/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { useDeveloperContext } from '@/pages/developer/context/DevelopersContext';
import * as Util from '@/pages/integrations/utils';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import * as SequencesAPI from '@/shared/api/sequences';
import {
  FormFieldWrapper,
  SelectCombobox,
  TextAreaInput,
  TextInput,
} from '@/shared/components/forms';
import { FormSelect } from '@/shared/components/forms/Select';
import { useDisclosure } from '@/shared/hooks';
import {
  AddToSequenceActionParams,
  CreateLeadActionParams,
  IntegrationConfiguration,
  JobBoardAutomationKeys,
  SendMessageActionParams,
  WhippyAction,
} from '@/shared/types/integrations';
import { Sequence } from '@/shared/types/sequences';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  HStack,
} from '@/shared/ui';
import { capitalizeWords } from '@/shared/utils/dromo';

import { useIntegrations } from '../context/IntegrationsContext';
import { useIntegrationConfigurations } from './context/IntegrationConfigurationContext';

type ConfigurationEditorProps = {
  configuration?: IntegrationConfiguration;
  applicationId: string;
  children: React.ReactNode;
};

export const IntegrationConfigurationEditor = (props: ConfigurationEditorProps) => {
  const { children, applicationId } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { integrationsState } = useIntegrations();
  const { current: currentIntegration } = integrationsState;
  const { createJobBoardConfiguration } = useIntegrationConfigurations();
  const { organizationId } = useAuth();
  const { channelsState } = useChannels();
  const { channels } = channelsState;
  const { developerState, getApiKeys } = useDeveloperContext();
  const { apiKeys } = developerState;

  const [actionType, setActionType] = useState<WhippyAction | null>(null);
  const [channelId, setChannelID] = useState<string>('');
  const [step, setStep] = useState(1);
  const [sequences, setSequences] = useState<Sequence[]>([]);
  const [currentSequenceID, setCurrentSequenceID] = useState<string>('');
  const [fromNumber, setFromNumber] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');
  const history = useHistory();

  // get sequences on mount
  useEffect(() => {
    const fetchSequences = async () => {
      const data = await SequencesAPI.getSequences();
      // this is here for typescript reasons since data could be a null[]
      if (data.data) {
        const nonNullData = data.data as Sequence[];
        setSequences(nonNullData);
      }
    };
    fetchSequences().finally(getApiKeys);
  }, []);

  if (currentIntegration == null) {
    return <div>Failed to load integration</div>;
  }

  // mapping the locations into the data structure that is needed for combobox
  const phoneNumberOptions =
    channels && channels.length > 0
      ? channels.map((channel) => ({
          value: channel.phone as string,
          type: `${channel.name} (${channel.phone})`,
        }))
      : [];

  // mapping apiKeys into the data structure that is needed for combobox
  const apiKeyOptions =
    apiKeys && apiKeys.length > 0
      ? apiKeys.map((apiKey) => ({
          // display the name
          type: `${apiKey?.name || 'No API Name'}`,
          // capture the key as the value
          value: apiKey?.key as string,
        }))
      : [];

  // mapping channelIds into the data structure that is needed for combobox
  const channelOptions =
    channels && channels.length > 0
      ? channels.map((channel) => ({
          // display the name
          type: channel.name as string,
          // capture the key as the value
          value: channel.id as string,
        }))
      : [];

  type FormValues = AddToSequenceActionParams &
    SendMessageActionParams &
    CreateLeadActionParams & {
      action_type: string;
      api_key: string;
      forwarding_email: string;
    };

  // This is a superset of AddToSequenceParams
  // SendMessageActionParams and CreateLeadActionParams
  const formInitialValue: FormValues = {
    action_type: '',
    from_number: '',
    outbound_body: '',
    outbound_attachment_url: '',
    delay_in_seconds: 180,
    forwarding_email: '',
    sequence_id: '',
    channel_id: '',
    api_key: '',
  };

  const determineValidation = (actionType: string | null): any => {
    if (!actionType) {
      return Yup.object({
        action_type: Yup.string().required('Required'),
      });
    }

    const requiredFields = {
      forwarding_email: Yup.string().email().required('Required'),
      api_key: Yup.string().uuid().required('Required'),
    };

    switch (actionType) {
      case WhippyAction.SEND_MESSAGE:
        return Yup.object({
          from_number: Yup.string().required('Required'),
          outbound_body: Yup.string().required('Required'),
          outbound_attachment_url: Yup.string().url(),
          delay_in_seconds: Yup.number().required('Required'),
          ...requiredFields,
        });
      case WhippyAction.ADD_TO_SEQUENCE:
        return Yup.object({
          from_number: Yup.string().required('Required'),
          sequence_id: Yup.string().uuid().required('Required'),
          ...requiredFields,
        });
      case WhippyAction.CREATE_LEAD:
        return Yup.object({
          from_number: Yup.string().required('Required'),
          outbound_body: Yup.string().required('Required'),
          outbound_attachment_url: Yup.string().url(),
          delay_in_seconds: Yup.number().required('Required'),
          channel_id: Yup.string().uuid().required('Required'),
          ...requiredFields,
        });
    }
  };

  // close the dialog and reset the step
  const onDialogClose = () => {
    onClose();
  };

  const SendMessageForm = () => {
    const { values } = useFormikContext<FormValues>();

    useEffect(() => {
      setFromNumber(values.from_number);
      setApiKey(values.api_key);
    }, [values]);

    return (
      <Box>
        <FormFieldWrapper
          name="from_number"
          tooltip="The number within your organization that you want the message to originate from"
          label="From Number"
        >
          <SelectCombobox
            placeholder={fromNumber || 'Select a number'}
            isDropdown={true}
            options={phoneNumberOptions}
            closeOnClick={true}
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          name="forwarding_email"
          tooltip="The email address you will forward job board notifications from"
          label="Email Address"
        >
          <TextInput name="forwarding_email" placeholder="john.doe@example.com" />
        </FormFieldWrapper>
        <FormFieldWrapper
          name="outbound_body"
          tooltip="The message you would like to send to each recipient on "
          label="Message"
        >
          <TextAreaInput
            name="outbound_body"
            placeholder="Hello and thank you for taking the time to apply!"
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          name="outbound_attachment_url"
          tooltip="An optional attachment you can include when creating a lead."
          label="Attachment (Optional)"
        >
          <TextInput
            name="outbound_attachment_url"
            placeholder="www.images.com/logo.jpg"
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          name="delay_in_seconds"
          tooltip="The amount of delay you want to add to message before it sends"
          label="Delay in Seconds"
        >
          <TextInput name="delay_in_seconds" placeholder="180" />
        </FormFieldWrapper>
        <FormFieldWrapper name="api_key" tooltip="The api key to use." label="API Key">
          <SelectCombobox
            placeholder={apiKey}
            isDropdown={true}
            options={apiKeyOptions}
            closeOnClick={true}
          />
        </FormFieldWrapper>
      </Box>
    );
  };

  const CreateLeadForm = () => {
    const { values } = useFormikContext<FormValues>();
    useEffect(() => {
      setFromNumber(values.from_number);
      setApiKey(values.api_key);
      setChannelID(values.channel_id);
    }, [values]);

    return (
      <Box>
        <FormFieldWrapper
          name="forwarding_email"
          tooltip="The email address you will forward job board notifications from"
          label="Email Address"
        >
          <TextInput name="forwarding_email" placeholder="john.doe@example.com" />
        </FormFieldWrapper>
        <FormFieldWrapper
          name="from_number"
          tooltip="The number within your organization that you want the message to originate from."
          label="From Number"
        >
          <SelectCombobox
            placeholder={fromNumber}
            isDropdown={true}
            options={phoneNumberOptions}
            closeOnClick={true}
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          name="outbound_body"
          tooltip="The message you would like to send to each recipient on "
          label="Message"
        >
          <TextAreaInput
            name="outbound_body"
            placeholder="Hello and thank you for taking the time to apply!"
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          name="outbound_attachment_url"
          tooltip="An optional attachment you can include when creating a lead."
          label="Attachment (Optional)"
        >
          <TextInput
            name="outbound_attachment_url"
            placeholder="www.images.com/logo.jpg"
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          name="delay_in_seconds"
          tooltip="The amount of delay you want to add to message before it sends"
          label="Delay in Seconds"
        >
          <TextInput name="delay_in_seconds" placeholder="180" />
        </FormFieldWrapper>
        <FormFieldWrapper
          name="channel_id"
          tooltip="The id of the channel"
          label="Channel ID"
        >
          <SelectCombobox
            placeholder={channelId}
            isDropdown={true}
            options={channelOptions}
            closeOnClick={true}
          />
        </FormFieldWrapper>
        <FormFieldWrapper name="api_key" tooltip="The api key to use." label="API Key">
          <SelectCombobox
            placeholder={apiKey}
            isDropdown={true}
            options={apiKeyOptions}
            closeOnClick={true}
          />
        </FormFieldWrapper>
      </Box>
    );
  };

  const AddToSequenceForm = () => {
    const options =
      sequences && sequences.length > 0
        ? sequences.map((sequence: Sequence) => ({
            value: sequence?.id as string,
            type: sequence?.title as string,
          }))
        : [];

    const { values } = useFormikContext<FormValues>();

    useEffect(() => {
      setFromNumber(values.from_number);
      setCurrentSequenceID(values.sequence_id);
      setApiKey(values.api_key);
    }, [values]);

    return (
      <Box>
        <FormFieldWrapper
          name="from_number"
          tooltip="The number within your organization that you want the message to originate from."
          label="From Number"
        >
          <SelectCombobox
            placeholder={fromNumber}
            isDropdown={true}
            options={phoneNumberOptions}
            closeOnClick={true}
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          name="forwarding_email"
          tooltip="The email address you will forward job board notifications from"
          label="Email Address"
        >
          <TextInput name="forwarding_email" placeholder="john.doe@example.com" />
        </FormFieldWrapper>
        <FormFieldWrapper
          name="sequence_id"
          tooltip="The ID of the sequence you would like to add the applicants to"
          label="Sequence ID"
        >
          <SelectCombobox
            placeholder={currentSequenceID}
            isDropdown={true}
            options={options}
            closeOnClick={true}
          />
        </FormFieldWrapper>
        <FormFieldWrapper name="api_key" tooltip="The api key to use." label="API Key">
          <SelectCombobox
            placeholder={apiKey}
            isDropdown={true}
            options={apiKeyOptions}
            closeOnClick={true}
          />
        </FormFieldWrapper>
      </Box>
    );
  };

  const whippyActionTypeOptions = [
    {
      label: 'Send Message',
      value: WhippyAction.SEND_MESSAGE,
    },
    {
      label: 'Add to Sequence',
      value: WhippyAction.ADD_TO_SEQUENCE,
    },
    {
      label: 'Create Lead',
      value: WhippyAction.CREATE_LEAD,
    },
  ];

  return (
    <Dialog open={isOpen} modal={true}>
      <DialogTrigger onClick={onOpen}>{children}</DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" />
        <DialogContent
          onEscapeKeyDown={onDialogClose}
          onPointerDownOutside={onDialogClose}
        >
          <DialogHeader
            title={
              actionType != null
                ? capitalizeWords(Util.deSnakeCase(actionType.toString()))
                : 'New Integration'
            }
          />
          <Box>
            <Formik
              initialValues={formInitialValue}
              validationSchema={determineValidation(actionType)}
              onSubmit={async (values: FormValues) => {
                onClose();
                setStep(0);
                await createJobBoardConfiguration(
                  values.api_key,
                  currentIntegration.key as unknown as JobBoardAutomationKeys,
                  applicationId,
                  organizationId,
                  values
                );
                history.replace(`/integrations/${currentIntegration.id}/configurations`);
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  {step === 1 && (
                    <Box>
                      <FormFieldWrapper
                        name="action_type"
                        tooltip="The type of Whippy Action you would like to link"
                        label="Whippy Action"
                      >
                        <FormSelect
                          placeholder="Whippy Action Types"
                          options={whippyActionTypeOptions}
                        />
                      </FormFieldWrapper>
                    </Box>
                  )}
                  {step === 2 &&
                    formik.values.action_type === WhippyAction.SEND_MESSAGE && (
                      <SendMessageForm />
                    )}
                  {step === 2 &&
                    formik.values.action_type === WhippyAction.CREATE_LEAD && (
                      <CreateLeadForm />
                    )}
                  {step === 2 &&
                    formik.values.action_type === WhippyAction.ADD_TO_SEQUENCE && (
                      <AddToSequenceForm />
                    )}
                  <DialogFooter justify="end">
                    <HStack>
                      <Button variant="gray" onClick={onClose} type="button">
                        Cancel
                      </Button>
                      {step === 1 && (
                        <Button
                          onClick={() => {
                            setStep(step + 1);
                            // map the action_type string to the WhippyAction
                            const selectedAction = formik.values
                              .action_type as WhippyAction;
                            setActionType(selectedAction);
                          }}
                          type="button"
                          disabled={formik.values.action_type === ''}
                        >
                          Next Step
                        </Button>
                      )}
                      {step === 2 && (
                        <>
                          <Button variant="gray" onClick={() => setStep(1)} type="button">
                            Back
                          </Button>
                          <Button type="submit">Submit</Button>
                        </>
                      )}
                    </HStack>
                  </DialogFooter>
                </form>
              )}
            </Formik>
          </Box>
          <DialogCloseIcon onClick={() => onClose()} size="2">
            <HiX size="15px" style={{ color: 'white' }} />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
