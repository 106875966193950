import { useEffect, useState } from 'react';
import { HiDuplicate } from 'react-icons/hi';

import { OpeningHoursTimePicker } from '@/shared/components/timepicker/OpeningHoursTimePicker';
import { useDisclosure } from '@/shared/hooks';
import { OpeningHour } from '@/shared/types';
import { Channel } from '@/shared/types/channels';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@/shared/ui';
import { Switch, SwitchThumb } from '@/shared/ui/Switch';

import { WeekDaySelector } from './WeekDaySelector';

// Applies the opening hours to the selected days
export const applyOpeningHours = (
  location: Channel,
  sourceDay: string,
  targetDays: Array<string>
): Channel => {
  // Assuming 'sourceDay' contains the hours to be copied
  const sourceOpeningHour = location?.opening_hours?.find(
    (openingHour) => openingHour.weekday === sourceDay
  );

  // If the source day is not found, return the location as is
  if (!sourceOpeningHour) {
    return location;
  }

  // Modify the opening hours of the target days to match the source day
  const updatedOpeningHours = location?.opening_hours?.map((currentOpeningHour) => {
    if (targetDays.includes(currentOpeningHour.weekday)) {
      return {
        ...currentOpeningHour,
        opens_at: sourceOpeningHour.opens_at,
        closes_at: sourceOpeningHour.closes_at,
        state: sourceOpeningHour.state,
      };
    }
    return currentOpeningHour;
  });

  return {
    ...location,
    opening_hours: updatedOpeningHours,
  };
};

export const OpeningHourSelector = (props: {
  openingHour: OpeningHour;
  onChange: (
    openingHour: {
      id: string;
      opens_at: string;
      closes_at: string;
      weekday: string;
      state: string;
      isSwitch?: boolean | undefined;
      timezone?: string;
    },
    nextState?: string
  ) => void;
  onApply: (openingHour: OpeningHour, targetDays: Array<string>) => void;
  firstRender: number;
  switchColor?: string;
  disabled?: boolean;
  isCopyDisabled?: boolean;
}) => {
  const {
    openingHour,
    onChange,
    firstRender,
    switchColor,
    onApply,
    isCopyDisabled,
    disabled,
  } = props;

  const nextState = openingHour.state === 'open' ? 'closed' : 'open';

  useEffect(() => {
    if (openingHour.opens_at == null) {
      openingHour.opens_at = '00:00';
    }
    if (openingHour.closes_at == null) {
      openingHour.closes_at = '00:00';
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openingHour?.closes_at, openingHour?.opens_at]);

  // WeekDaySelector popover state
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Stores the weekday of the opening hour being copied from (source day)
  const [sourceDay, setSourceDay] = useState('');

  // Stores the days to apply the opening hours to
  const [targetDays, setTargetDays] = useState<string[]>([]);

  // Event handler for when the user checks/unchecks a day's checkbox
  const handleCheckboxClick = (weekday: string, isChecked: boolean) => {
    setTargetDays((prevDays) => {
      // If the day is already in the list, remove it
      if (!isChecked) {
        return prevDays.filter((day) => day !== weekday);
      }
      if (prevDays.includes(weekday) && isChecked) {
        return prevDays;
      }

      // If the day is not in the list, add it
      return [...prevDays, weekday];
    });
  };

  // Handles the click event for the duplicate button
  const handleDuplicateClick = () => {
    // Set the current day as the source day
    setSourceDay(openingHour.weekday);

    // Opens the popover
    onOpen();
  };

  // Inline styles for the popover content
  const popoverContentStyles = {
    borderRadius: '8px',
    boxShadow: '0 6px 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    paddingTop: '20px',
    backgroundColor: 'white',
    border: '1px solid #e1e1e1',
  };

  // Applies the opening hours to the selected days
  const onCopyApply = () => {
    onApply(openingHour, targetDays);
    setTargetDays([]);
    onClose();
  };

  // Update the opening hour state when the switch is toggled
  const onSwitch = () => {
    const updatedOpeningHour = {
      ...openingHour,
      state: nextState,
      isSwitch: true,
    };
    onChange(updatedOpeningHour);
  };

  // Update the opening hour when the time is changed
  const onTimeChange = (updatedValue: any, isOpen: boolean) => {
    const updatedOpeningHour = {
      id: openingHour.id,
      state: openingHour.state,
      weekday: openingHour.weekday,
      opens_at: isOpen ? updatedValue.opens_at : openingHour.opens_at,
      closes_at: isOpen ? openingHour.closes_at : updatedValue.closes_at,
    };
    onChange(updatedOpeningHour);
  };

  return (
    <Flex justify="between" css={{ fontSize: 13 }}>
      <Flex
        align="center"
        css={{
          minWidth: 85,
          pt: firstRender === 0 ? '7%' : '0',
          fontSize: 13,
          fontWeight: 600,
        }}
      >
        {openingHour.weekday}
      </Flex>
      <Flex
        align="center"
        css={{
          position: 'relative',
          paddingTop: firstRender === 0 ? '7%' : '0',
          display: 'none',
          '@md': {
            display: 'flex',
          },
        }}
      >
        <Switch
          checked={openingHour.state === 'open'}
          data-testid={`business-hours-switch-${openingHour.weekday}`}
          onCheckedChange={onSwitch}
          css={{
            '&[data-state="checked"]': {
              backgroundColor: switchColor ? switchColor : undefined,
            },
          }}
          disabled={disabled}
        >
          <SwitchThumb />
        </Switch>
        <Text css={{ marginLeft: 10, fontWeight: '600' }}>Open</Text>
      </Flex>
      <HStack>
        <Box data-testid={`business-hours-open-time-${openingHour.weekday}`}>
          {firstRender === 0 ? (
            <Text css={{ align: 'left', fontWeight: '600', marginBottom: 20 }}>Open</Text>
          ) : null}
          <OpeningHoursTimePicker
            state={openingHour.state === 'open'}
            value={openingHour.opens_at || '00:00'}
            openingHour={openingHour}
            request={(updatedValue) => onTimeChange(updatedValue, true)}
            opening={true}
            open24Hours={
              openingHour.closes_at === '00:00' && openingHour.opens_at === '00:00'
                ? true
                : false
            }
          />
        </Box>
        <Box data-testid={`business-hours-close-time-${openingHour.weekday}`}>
          {firstRender === 0 ? (
            <Text css={{ align: 'left', marginBottom: 20, fontWeight: '600' }}>
              Closed
            </Text>
          ) : null}
          <OpeningHoursTimePicker
            state={openingHour.state === 'open'}
            value={openingHour.closes_at || '00:00'}
            openingHour={openingHour}
            request={(updatedValue) => onTimeChange(updatedValue, false)}
            opening={false}
            open24Hours={
              openingHour.closes_at === '00:00' && openingHour.opens_at === '00:00'
                ? true
                : false
            }
          />
        </Box>
      </HStack>
      <IconButton
        onClick={handleDuplicateClick}
        disabled={isCopyDisabled}
        css={{
          marginTop: firstRender === 0 ? '40px' : '0',
        }}
        data-testid={`business-hours-popOver-trigger-${openingHour.weekday}`}
      >
        <HiDuplicate size={'17px'} />
      </IconButton>
      <Popover open={isOpen} onOpenChange={onClose}>
        <PopoverTrigger asChild>
          <Box />
        </PopoverTrigger>
        <PopoverContent style={popoverContentStyles}>
          <WeekDaySelector
            targetDays={targetDays}
            sourceDay={sourceDay}
            handleCheckboxClick={handleCheckboxClick}
            onApply={onCopyApply}
          />
        </PopoverContent>
      </Popover>
    </Flex>
  );
};
