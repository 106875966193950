/* eslint-disable @typescript-eslint/no-unused-vars */
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import { getUploadsV2 } from '@/shared/api/contacts/uploads';
import { searchContacts } from '@/shared/api/contacts/v2';
import { getTagsV2 } from '@/shared/api/tags';
import { Contact } from '@/shared/types';
import { SearchFilters } from '@/shared/types/contacts';
import { Group } from '@/shared/types/contacts/groups';
import { FilterItem, Sort } from '@/shared/types/filter';
import { Tag } from '@/shared/types/tags';
import { Flex, Text } from '@/shared/ui';
import { phoneFormatting } from '@/shared/utils/validations/validations';
import TabbedCombobox from '@/shared/v2/components/tabbedCombobox/TabbedCombobox';

import { appendNewFilterType, getFilterItemsByType, groupFiltersByType } from './utils';

type AudienceResourceType = 'contact' | 'tag' | 'segment' | 'list';
type Tab = 'contacts' | 'tags' | 'uploads' | 'segments';
export type AudienceItem = {
  data: Contact | Tag | Group;
  type: AudienceResourceType;
};

type AudienceQuickFilterProps = {
  onSelectedItemsChanged: (filter: FilterItem[]) => void;
  initialSelectedItems: FilterItem[];
  channelId: string | null;
};

const AudienceQuickFilter = ({
  initialSelectedItems,
  onSelectedItemsChanged,
  channelId,
}: AudienceQuickFilterProps): JSX.Element => {
  const [isLoading, setLoading] = useState(true);
  // We need some way to determine was the last form of requesting more search data.
  // The two options are we scrolled to the bottom and we're need more data OR
  // we typed some new text. This helps us prevent invalid states such as
  // requesting more data in the situation where searching causes us to hit the bottom reached
  // state.
  const [searchEvent, setSearchEvent] = useState<'scroll' | 'search' | null>();
  const [loadedResultItems, setLoadedResultItems] = useState(false);
  const [selectedComboboxItems, setSelectedComboboxItems] = useState<
    { id: string; value: string; type: AudienceResourceType }[]
  >([]);

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [totalContacts, setTotalContacts] = useState<number>(0);
  const [contactsOffset, setContactsOffset] = useState(0);

  const [tags, setTags] = useState<Tag[]>([]);
  const [totalTags, setTotalTags] = useState<number>(0);
  const [tagsOffset, setTagsOffset] = useState(0);

  const [uploadLists, setUploadLists] = useState<Tag[]>([]);
  const [totalUploadLists, setTotalUploadLists] = useState<number>(0);
  const [uploadListsOffset, setUploadListsOffset] = useState(0);

  const [_segments, _setSegments] = useState<Group[]>([]);
  const [_totalSegments, _setTotalSegments] = useState<number>(0);
  const [_segmentsOffset, _setSegmentsOffset] = useState(0);

  useEffect(() => {
    const initialSearchFilter = {
      filter: [],
      searchFilter: [],
      sort: [],
      limit: 100,
      offset: 0,
    };

    const fetchInitialSearchData = async () => {
      const { data: contactsData, total: totalContacts } =
        await getContacts(initialSearchFilter);
      const { data: tagsData, total: totalTags } = await getTags(initialSearchFilter);
      const { data: uploadListData, total: totalUploadLists } =
        await getUploadLists(initialSearchFilter);
      // const { data: segmentsData, total: totalSegments } =
      //   await getSegments(initialSearchFilter);
      const filteredContacts = contactsData.filter(
        (contact) => contact.name != '' && contact.name != null
      );
      setContacts(filteredContacts);
      setTotalContacts(totalContacts);

      setTags(tagsData);
      setTotalTags(totalTags);

      setUploadLists(uploadListData);
      setTotalUploadLists(totalUploadLists);

      // setSegments(segmentsData);
      // setTotalSegments(totalSegments);

      setLoading(false);
    };

    setLoading(true);
    fetchInitialSearchData();
  }, []);

  useEffect(() => {
    const fetchInitialItems = async (initialFilter: FilterItem[]) => {
      const contactsFilter = getFilterItemsByType('contact', initialFilter);
      const tagsFilter = getFilterItemsByType('tag', initialFilter);
      const listsFilter = getFilterItemsByType('list', initialFilter);
      // const segmentsFilter = getFilterItemsByType('segment', initialFilter)
      const selectedItems: { id: string; value: string; type: AudienceResourceType }[] =
        [];
      if (contactsFilter.length > 0) {
        const contacts = await getContacts({
          filter: contactsFilter,
          limit: 100,
          offset: 0,
          sort: [],
          searchFilter: [],
        });
        contacts.data.forEach((contact) => {
          selectedItems.push({
            type: 'contact',
            id: contact.id,
            value: contact.name ?? '',
          });
        });
      }
      if (tagsFilter.length > 0) {
        const tags = await getTags({
          filter: tagsFilter,
          limit: 100,
          offset: 0,
          sort: [],
          searchFilter: [],
        });
        tags.data.forEach((tag) => {
          selectedItems.push({
            type: 'tag',
            id: tag.id,
            value: tag.name ?? '',
          });
        });
      }
      if (listsFilter.length > 0) {
        const lists = await getUploadLists({
          filter: listsFilter,
          limit: 100,
          offset: 0,
          sort: [],
          searchFilter: [],
        });
        lists.data.forEach((listUpload) => {
          selectedItems.push({
            type: 'list',
            id: listUpload.id,
            value: listUpload.name ?? '',
          });
        });
      }
      // if (segmentsFilter.length > 0) {
      //   const segments = await getSegments({
      //     filter: segmentsFilter,
      //     limit: 100,
      //     offset: 0,
      //     sort: [],
      //     searchFilter: [],
      //   });
      //   segments.data.forEach((segment) => {
      //     selectedItems.push({
      //       type: 'segment',
      //       id: segment.id,
      //       value: segment.name ?? '',
      //     });
      //   });
      // }
      setSelectedComboboxItems(selectedItems);
      // onSelectedItemsChanged(initialFilter);
      setLoading(false);
      setLoadedResultItems(true);
    };
    setLoading(true);
    if (!loadedResultItems && initialSelectedItems.length > 0) {
      fetchInitialItems(initialSelectedItems);
    } else {
      setLoading(false);
    }
  }, [initialSelectedItems]);

  // make data requests for the new data
  const onSearch = async (searchInput: string) => {
    if (isLoading) return;
    setSearchEvent('search');
    setLoading(true);
    const baseSearchFilter = {
      filter: [] as FilterItem[],
      sort: [] as Sort[],
      limit: 100,
      offset: 0,
    };
    const { data: contactsData, total: totalContacts } = await getContacts({
      ...baseSearchFilter,
      searchFilter: createSearchFilter('contact', searchInput),
    });
    const { data: tagsData, total: totalTags } = await getTags({
      ...baseSearchFilter,
      searchFilter: createSearchFilter('tag', searchInput),
    });
    const { data: uploadListData, total: totalUploadLists } = await getUploadLists({
      ...baseSearchFilter,
      searchFilter: createSearchFilter('list', searchInput),
    });
    // const { data: segmentsData, total: totalSegments } = await getSegments({
    //   ...baseSearchFilter,
    //   searchFilter: createSearchFilter('segment', searchInput),
    // });
    setContacts(contactsData);
    setTags(tagsData);
    setUploadLists(uploadListData);
    // setSegments(segmentsData);

    setTotalContacts(totalContacts);
    setTotalTags(totalTags);
    setTotalUploadLists(totalUploadLists);
    // setTotalSegments(totalSegments)
    setLoading(false);
  };

  const fetchData = async (tab: Tab, searchInput: string) => {
    const baseSearchFilter = {
      filter: [] as FilterItem[],
      sort: [] as Sort[],
      limit: 100,
    };
    switch (tab) {
      case 'contacts': {
        setLoading(true);
        const offset = calculateOffset(contactsOffset, totalContacts);
        const { data: contactsData } = await getContacts({
          ...baseSearchFilter,
          searchFilter: createSearchFilter('contact', searchInput),

          sort: [],
          offset,
        });
        updateStateAfterScrollLoad(tab, contactsData, totalContacts);
        break;
      }
      case 'tags': {
        setLoading(true);
        const offset = calculateOffset(tagsOffset, totalTags);
        const { data: tagsData } = await getTags({
          ...baseSearchFilter,
          searchFilter: createSearchFilter('tag', searchInput),
          sort: [],
          offset,
        });
        updateStateAfterScrollLoad(tab, tagsData, totalTags);
        break;
      }
      case 'uploads': {
        setLoading(true);
        const offset = calculateOffset(uploadListsOffset, totalUploadLists);
        const { data: uploadListsData } = await getUploadLists({
          ...baseSearchFilter,
          searchFilter: createSearchFilter('list', searchInput),
          sort: [],
          offset,
        });
        updateStateAfterScrollLoad(tab, uploadListsData, totalUploadLists);
        break;
      }
      // case 'segments': {
      // const offset = Math.min(segmentsOffset + 100, totalSegments - 100)
      //   const { data: segmentsData} = await getSegments({
      //     ...baseSearchFilter,
      //     searchFilter: createSearchFilter('segment', searchInput),
      //     offset
      //   });
      //   updateStateAfterScrollLoad(tab, segmentsData, totalSegments);
      //   break;
      // }
    }
  };

  const updateStateAfterScrollLoad = (
    tab: Tab,
    data: Contact[] | Tag[] | Group[],
    totalCount: number
  ) => {
    switch (tab) {
      case 'contacts':
        setTotalContacts(totalCount);
        setContacts((prev) => [...prev, ...(data as Contact[])]);
        setContactsOffset((prev) => Math.min(prev + 100, totalCount));
        setLoading(false);
        break;
      case 'tags':
        setTotalTags(totalCount);
        setTags((prev) => [...prev, ...(data as Tag[])]);
        setTagsOffset((prev) => Math.min(prev + 100, totalCount));
        setLoading(false);
        break;
      case 'uploads':
        setTotalUploadLists(totalCount);
        setUploadLists((prev) => [...prev, ...(data as Tag[])]);
        setUploadListsOffset((prev) => Math.min(prev + 100, totalCount));
        setLoading(false);
        break;
      // case 'segments':
      //   setTotalSegments(totalCount);
      //   setSegments((prev) => [...prev, ...(data as Group[])]);
      //   setSegmentsOffset((prev) => Math.min(prev + 100, totalCount));
      //   setLoading(false);
      //   break;
    }
  };

  return (
    <Flex css={{ width: '100%', position: 'relative' }}>
      <TabbedCombobox
        defaultTab="contacts"
        onEndReached={(tab, searchInput) => {
          if (searchEvent == 'scroll') {
            fetchData(tab as Tab, searchInput);
          }
        }}
        isScrolling={(isScrolling) =>
          setSearchEvent((prev) => {
            if (isScrolling) {
              return 'scroll';
            }
            return prev;
          })
        }
        selectedItems={selectedComboboxItems}
        setSelectedItems={(selectedItems) => {
          const newFilter = differenceFilter(
            selectedItems as { id: string; type: AudienceResourceType; value: string }[]
          );
          onSelectedItemsChanged(newFilter);
          setSelectedComboboxItems(
            selectedItems as {
              id: string;
              value: string;
              type: AudienceResourceType;
            }[]
          );
        }}
        onSearch={debounce(onSearch, 1000)}
      >
        <Flex
          direction="column"
          css={{
            border: '1px solid #0134DB72 ',
            borderRadius: '3px',
            padding: selectedComboboxItems.length > 0 ? '8px 4px' : '0px',
            gap: selectedComboboxItems.length > 0 ? '8px' : '0px',
            width: '100%',
          }}
        >
          <TabbedCombobox.ResultsList
            style={{
              flexWrap: 'wrap',
              padding: selectedComboboxItems.length > 0 ? '0px 4px' : '0px',
              gap: selectedComboboxItems.length > 0 ? '8px' : '0px',
            }}
          >
            {selectedComboboxItems.map((value) => (
              <TabbedCombobox.ResultItem
                key={value.id}
                value={value.value}
                id={value.id}
              />
            ))}
          </TabbedCombobox.ResultsList>
          <TabbedCombobox.Input placeholder="Search for audience" />
        </Flex>
        <TabbedCombobox.Popover
          style={{
            border: '1px solid var(--colors-slate4)',
            background: '#FFF',
            boxShadow:
              '0px 16px 36px -20px rgba(1, 6, 47, 0.17)), 0px 16px 64px 0px rgba(5, 5, 88, 0.02)), 0px 12px 60px 0px  rgba(0, 0, 0, 0.05))',
            borderRadius: '8px',
            marginTop: '16px',
            marginLeft: '-8px',
            marginRight: '-24px',
          }}
        >
          <TabbedCombobox.Tabs>
            <TabbedCombobox.TabsList>
              <TabbedCombobox.TabsTrigger value="contacts">
                Contacts
              </TabbedCombobox.TabsTrigger>
              <TabbedCombobox.TabsTrigger value="uploads">
                Uploads
              </TabbedCombobox.TabsTrigger>
              <TabbedCombobox.TabsTrigger value="tags">Tags</TabbedCombobox.TabsTrigger>
              {/* <TabbedCombobox.TabsTrigger value="segments"> */}
              {/*   Segments */}
              {/* </TabbedCombobox.TabsTrigger> */}
            </TabbedCombobox.TabsList>
            {channelId == null ? (
              <NoChannelState />
            ) : (
              <Flex>
                <TabbedCombobox.TabsContent
                  value="contacts"
                  isLoading={isLoading}
                  items={contacts}
                  totalItems={totalContacts}
                />
                <TabbedCombobox.TabsContent
                  value="tags"
                  isLoading={isLoading}
                  items={tags}
                  totalItems={totalTags}
                />
                <TabbedCombobox.TabsContent
                  value="uploads"
                  isLoading={isLoading}
                  items={uploadLists}
                  totalItems={totalUploadLists}
                />
                {/* <TabbedCombobox.TabsContent */}
                {/*   value="segments" */}
                {/*   isLoading={isLoading} */}
                {/*   items={segments} */}
                {/*   totalItems={totalSegments} */}
                {/* /> */}
              </Flex>
            )}
          </TabbedCombobox.Tabs>
        </TabbedCombobox.Popover>
      </TabbedCombobox>
    </Flex>
  );
};

const NoChannelState = () => {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      css={{
        width: '100%',
        height: '360px',
      }}
    >
      <Text variant="bold" size="3">
        {' '}
        No Channel Selected{' '}
      </Text>
      <p>
        Please choose a channel for the campaign before searching through your contacts.
      </p>
    </Flex>
  );
};

async function getContacts(params: SearchFilters) {
  const filters = prepareFilters(params);
  return await searchContacts(
    filters.filter,
    [
      ...filters.sort,
      {
        resource: 'contact',
        column: 'updated_at',
        order: 'desc',
      } as Sort,
    ],
    filters.limit,
    filters.offset
  );
}

async function getTags(params: SearchFilters) {
  const defaultSort = {
    resource: 'tag',
    column: 'updated_at',
    order: 'desc',
  } as Sort;
  const filters = prepareFilters({
    ...params,
    sort: [...params.sort, defaultSort],
    filter: [...params.filter, ...defaultTagFilter],
  });
  return await getTagsV2(filters);
}

async function getUploadLists(params: SearchFilters) {
  const defaultSort = {
    resource: 'list',
    column: 'updated_at',
    order: 'desc',
  } as Sort;
  const filters = prepareFilters({
    ...params,
    sort: [...params.sort, defaultSort],
    filter: [...params.filter, ...defaultUploadListFilter],
  });
  return await getUploadsV2(filters);
}

// async function getSegments(params: SearchFilters) {
//   const filters = prepareFilters(params);
//   return await getGroupsV2(filters, undefined);
// }

const defaultUploadListFilter: FilterItem[] = [
  { column: 'type', comparison: '==', resource: 'list', value: 'upload' },
  { column: 'state', comparison: '==', resource: 'list', value: 'active' },
];

const defaultTagFilter: FilterItem[] = [
  { column: 'type', comparison: '==', resource: 'tag', value: 'standard' },
  { column: 'state', comparison: '==', resource: 'tag', value: 'active' },
];

function differenceFilter(
  selectedComboboxItems: { id: string; value: string; type: AudienceResourceType }[]
): FilterItem[] {
  const newFilter = [] as FilterItem[];
  ['contact', 'tag', 'list'].forEach((type) => {
    // get each filter by type in a simplistic format
    const filtersByType = selectedComboboxItems
      .filter((item) => item.type == type)
      .map((newlySelectedItem) => {
        return {
          resource: type,
          column: 'id',
          comparison: '==',
          value: newlySelectedItem.id,
        };
      });
    // takes the simplistic format and reasonably groups the filters together
    const groupedByType = groupFiltersByType(filtersByType);
    appendNewFilterType(newFilter, groupedByType);
  });
  return newFilter;
}

function createSearchFilter(
  tab: AudienceResourceType,
  searchInput: string
): FilterItem[] {
  if (searchInput == '') return [];
  if (tab == 'contact') {
    return [
      {
        column: 'name',
        comparison: 'ilike',
        resource: 'contact',
        value: `%${searchInput}%`,
        or: [
          {
            column: 'email',
            comparison: 'ilike',
            resource: 'contact',
            value: `%${searchInput}%`,
            or: [
              {
                column: 'phone',
                comparison: 'ilike',
                resource: 'contact',
                value: `%${phoneFormatting(searchInput)}%`,
              },
            ],
          },
        ],
      },
    ];
  } else {
    return [
      {
        column: 'name',
        comparison: 'ilike',
        resource: tab,
        value: `%${searchInput}%`,
      },
    ];
  }
}

function calculateOffset(currentOffset: number, totalCount: number) {
  // cap the offset to be below the total count
  const offset = Math.min(currentOffset + 100, totalCount - 100);
  // don't let the offset be negative
  return Math.max(offset, 0);
}

export default AudienceQuickFilter;
