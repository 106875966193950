import * as SelectPrimitive from '@radix-ui/react-select';
import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { HiChevronDown, HiChevronUp, HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { useDisclosure } from '@/shared/hooks';
import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@/shared/ui';

import { useChannels } from '../channels/context/ChannelContext';
import { assignmentMethods } from './constants';
import { useTeams } from './context/TeamsContext';

export const AddTeam = () => {
  const { createTeam } = useTeams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  const {
    channelsState: { channels },
  } = useChannels();
  const [selectedLocations, setSelectedLocations] = useState({ locations: [] });
  const [assignmentMethod, setAssignmentMethod] = useState(assignmentMethods[0].value);

  // disable submit btn if location permission is selected without picking location/s
  const shouldDisableSubmitBtn = selectedLocations.locations.length < 1;

  // handle create team
  const handleSubmit = useCallback(
    async (
      values: {
        name: string;
        assignment_method: string;
        locations: Array<string>;
      },
      actions: any
    ) => {
      try {
        const params = {
          name: values.name,
          assignment_method: assignmentMethod,
          locations: selectedLocations.locations,
        };
        const data = await createTeam(params);
        actions.resetForm({
          values: {
            name: '',
            assignment_method: assignmentMethods[0].value,
            locations: [],
          },
        });
        onClose();
        if (data) {
          history.push(`/settings/teams/${data.id}`);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [assignmentMethod, selectedLocations.locations]
  );

  return (
    <Dialog open={isOpen} onOpenChange={() => !isOpen}>
      <DialogTrigger asChild>
        <Button onClick={onOpen} data-intercom-target="add-team-button">
          Add Team
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent onEscapeKeyDown={onClose} onPointerDownOutside={onClose}>
            <DialogTitle css={{ fontSize: 20, fontWeight: 700, marginBottom: 8 }}>
              Create a Team
            </DialogTitle>
            <Formik
              initialValues={{
                name: '',
                assignment_method: '',
                locations: [],
              }}
              validationSchema={Yup.object({
                name: Yup.string().test(
                  'len',
                  'Must be at least 3 characters',
                  (val) => val !== undefined && val.length > 2
                ),
              })}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} data-testid="create-team-form">
                  <FormFieldWrapper label="Team Name" name="name">
                    <TextInput placeholder="Example: Sales" />
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Assignment method" name="assignment_method">
                    <Select
                      value={assignmentMethod}
                      onValueChange={(e: string) => setAssignmentMethod(e)}
                    >
                      <SelectTrigger aria-label="assignment-option-select-trigger">
                        <SelectValue />
                        <SelectIcon>
                          <HiChevronDown />
                        </SelectIcon>
                      </SelectTrigger>
                      <SelectPrimitive.Portal>
                        <SelectContent css={{ zIndex: 9999999 }}>
                          <SelectScrollUpButton>
                            <HiChevronUp />
                          </SelectScrollUpButton>
                          <SelectViewport>
                            <SelectGroup>
                              <SelectLabel>Assignment method</SelectLabel>
                              {assignmentMethods.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                  <SelectItemIndicator />
                                  <SelectItemText>{option.label}</SelectItemText>
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectViewport>
                        </SelectContent>
                      </SelectPrimitive.Portal>
                    </Select>
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Channels" name="locations">
                    {channels.length > 0 && (
                      <MultiSelect
                        defaultPlaceholder="Channels"
                        defaultSelectedItems={[]}
                        isDropdown={true}
                        options={channels.map((channel: any) => ({
                          type: channel?.name || '',
                          value: channel.id,
                        }))}
                        parentSelectedItems={selectedLocations}
                        setParentSelectedItems={setSelectedLocations}
                        isCampaigns={true}
                      />
                    )}
                  </FormFieldWrapper>
                  <DialogFooter justify="end" css={{ mt: 15 }}>
                    <DialogClose asChild>
                      <Button variant="grayBackground" css={{ mr: 8 }} onClick={onClose}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button
                        type="submit"
                        disabled={shouldDisableSubmitBtn || !formik.isValid}
                      >
                        Save Team
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogClose asChild>
              <DialogCloseIcon onClick={onClose} size="2">
                <HiX style={{ color: 'white' }} />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
