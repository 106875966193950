import React, { useEffect, useState } from 'react';

import { OpeningHourSelector } from '@/shared/components/openingHours/OpeningHourSelector';
import { TimezonePicker } from '@/shared/components/timezonepicker/TimezonePicker';
import { Channel } from '@/shared/types/channels';
import { OpeningHour } from '@/shared/types/channels/hours';
import { Team, TeamMember } from '@/shared/types/team';
import { WorkSchedule } from '@/shared/types/team/schedule';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Box,
  Fieldset,
  Flex,
  HStack,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio,
  Text,
  VStack,
} from '@/shared/ui';
import { sortWeekDays } from '@/shared/utils/sortWeekDays/sortWeekDays';
import { styled } from '@/stitches.config';

import { initialWorkSchedules } from './constants';

export const OpeningHours = ({
  onApply,
  onChange,
  current,
  radioValue,
  handleRadioChange,
  radioSectionRendered,
  updateTimezone,
  timezone,
}: {
  onApply: (openingHour: OpeningHour, targetDays: string[]) => Promise<void>;
  onChange: (
    openingHour: {
      id: string;
      opens_at: string;
      closes_at: string;
      weekday: string;
      state: string;
      isSwitch?: boolean;
      timezone?: string;
    },
    nextState?: string
  ) => void;
  current?: Channel | Team | TeamMember | any;
  radioValue?: string;
  handleRadioChange?: (value: 'default' | 'custom') => void;
  radioSectionRendered?: boolean;
  updateTimezone: (timezoneName: string) => void;
  timezone?: string | null;
}) => {
  // Set the current work schedules based on the current team / team member / location
  const [currentWorkSchedules, setCurrentWorkSchedules] = useState<Array<WorkSchedule>>(
    []
  );

  const [currentTimezone, setCurrentTimezone] = useState<string>(timezone || '');

  const setAndUpdateTimezone = (timezoneName: string) => {
    setCurrentTimezone(timezoneName);
    updateTimezone(timezoneName);
  };

  // Update the current work schedules when we have changes
  useEffect(() => {
    const days = initialWorkSchedules.map((day: WorkSchedule) => {
      const workDay = current?.openingHours?.find(
        (currentDay: OpeningHour | WorkSchedule) => currentDay.weekday === day.weekday
      );
      if (workDay) {
        return { ...workDay, timezone: currentTimezone };
      }
      return { ...day, timezone: currentTimezone };
    });
    setCurrentWorkSchedules(sortWeekDays(days));
  }, [current]);

  return (
    <Accordion css={{ background: 'white' }} type="single" collapsible>
      <AccordionItem value="business-hours" variant="neumorphic">
        <Flex justify="between" align="center">
          {radioSectionRendered ? (
            <>
              <VStack gap={4}>
                <Text css={{ fontWeight: 600 }}>Individual Business Hours</Text>
                <RadioGroup value={radioValue} onValueChange={handleRadioChange}>
                  <Flex gap={7}>
                    <HStack align="center">
                      <StyledRadioGroup
                        value="custom"
                        id="r1"
                        data-testid="custom-schedule-radio-button"
                      >
                        <StyledIndicator />
                      </StyledRadioGroup>
                      <Label htmlFor="r1" css={{ m: 0, ml: 10 }}>
                        Use custom schedule
                      </Label>
                    </HStack>
                    <HStack align="center">
                      <StyledRadioGroup
                        value="default"
                        id="r2"
                        data-testid="default-schedule-radio-button"
                      >
                        <StyledIndicator />
                      </StyledRadioGroup>
                      <Label htmlFor="r2" css={{ m: 0, ml: 10 }}>
                        Use default schedule
                      </Label>
                    </HStack>
                  </Flex>
                </RadioGroup>
              </VStack>
            </>
          ) : (
            <>
              <VStack gap={2}>
                <Text css={{ fontWeight: 600 }}>Business Hours</Text>
                <Text>Update the business hours</Text>
              </VStack>
            </>
          )}
          <AccordionTrigger data-testid="business-hours-open-trigger" />
        </Flex>
        <AccordionContent variant="neumorphic">
          <VStack gap={4}>
            <Fieldset>
              <Label>Time Zone</Label>
              <TimezonePicker
                timezone={currentTimezone}
                setTimezone={setAndUpdateTimezone}
                disabled={radioValue === 'default'}
              />
            </Fieldset>
            <VStack gap={4}>
              <Fieldset>
                <Label>Hours of Operation</Label>
                <VStack>
                  {currentWorkSchedules?.map(
                    (openingHour: WorkSchedule | OpeningHour, index: number) => (
                      <Box css={{ width: '100%' }} key={openingHour.weekday}>
                        <OpeningHourSelector
                          openingHour={openingHour as OpeningHour}
                          onChange={onChange}
                          onApply={onApply}
                          firstRender={index}
                          isCopyDisabled={
                            !current ||
                            !current.openingHours ||
                            (radioSectionRendered && radioValue === 'default')
                          }
                          disabled={radioSectionRendered && radioValue === 'default'}
                        />
                      </Box>
                    )
                  )}
                </VStack>
              </Fieldset>
            </VStack>
          </VStack>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

const StyledRadioGroup = styled(RadioGroupRadio, {
  width: 15,
  height: 15,
});

const StyledIndicator = styled(RadioGroupIndicator, {
  '&::after': {
    width: 8,
    height: 8,
  },
});
