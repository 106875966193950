/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HiSearch, HiUserAdd, HiUserCircle } from 'react-icons/hi';
import { useMedia } from 'react-use';

import { useDisclosure } from '@/shared/hooks';
import { Channel } from '@/shared/types/channels';
import { User, UserAttachment } from '@/shared/types/users';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogPortal,
  DialogTrigger,
  Flex,
  HStack,
  IconButton,
  Input,
  ScrollArea,
  Text,
  VStack,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { styled } from '@/stitches.config';

import { useUsers } from '../../../settings/organization/users/context/UserContext';
import { useConversation } from '../../context/ConversationContext';

type UnassignedUserType = {
  name: string;
  email: string;
  id: number;
  attachment?: UserAttachment;
};

type AssignConversationModalProps = {
  isSmall?: boolean;
};

// unassignedUser must be added to list items in order to work with downshift
const unassignedUser = {
  name: 'Unassign',
  email: '',
  id: 0,
};

export const AssignConversationModal = ({ isSmall }: AssignConversationModalProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { conversationState, handleAssignUser } = useConversation();
  const { current } = conversationState;

  const {
    userState: { users },
  } = useUsers();

  // unassigned user is added to state so it renders as part of the downshift list,
  // otherwise it is not filterable
  const [userList, setUserList] = useState([unassignedUser, ...users]);
  const [assignedUser, setAssignedUser] = useState<User | null>(null);
  const [assignedUserPhoto, setAssignedUserPhoto] = useState<string | undefined>('');
  const [selectedUser, setSelectedUser] = useState<number>(
    current?.assigned_user_id || 0
  );

  const isDesktop = useMedia('(min-width: 1200px)');

  const filteredUsers = useMemo(() => {
    return users.filter((user: User) => {
      const userLocations = user?.locations || [];
      const userInLocation = userLocations.find(
        (location: Channel) => location?.id === current?.location_id
      );
      return !!userInLocation; // Return true if userInLocation exists, false otherwise
    });
  }, [users, current?.location_id]);

  // update user list whenever a new user is added to organization
  useEffect(() => {
    setUserList([unassignedUser, ...filteredUsers]);
  }, [filteredUsers]);

  // update user list whenever a new user is added to organization & current change
  useEffect(() => {
    // find the user that is assigned to the current conversation
    // if current is not available, assignedUser will remain null
    const assignUser = current?.assigned_user_id
      ? filteredUsers.find((user: User) => user?.id === current?.assigned_user_id)
      : null;

    // if not undefined then set state
    if (assignUser) {
      setAssignedUser(assignUser);
      setSelectedUser(assignUser?.id);
    } else {
      setSelectedUser(0);
    }

    // useEffect will be triggered whenever users or current is changed
  }, [filteredUsers, current?.assigned_user_id]);

  // bug fixed: update user photo whenever the assignedUser change
  useEffect(() => {
    setAssignedUserPhoto(assignedUser?.attachment?.url || '');
  }, [assignedUser]);

  const assignConversationUser = useCallback(async () => {
    const assignUser = userList.find(
      (user: User | UnassignedUserType) => user?.id === selectedUser
    );

    assignUser && (await handleAssignUser(assignUser as User));
    onClose();
  }, [userList, selectedUser, handleAssignUser]);

  const onUserSearch = (value: string) => {
    // If the search bar is not empty
    if (value) {
      // Filter the userList to only include users whose name includes the search value
      // The search is case-insensitive as both the user name and search value are converted to lower case
      setUserList(
        [unassignedUser, ...filteredUsers].filter(
          (item: User | UnassignedUserType) =>
            item?.name?.toLowerCase().startsWith(value.toLowerCase() || '') ||
            item?.email?.toLowerCase().startsWith(value.toLowerCase() || '')
        )
      );
    } else {
      setUserList([unassignedUser, ...filteredUsers]);
    }
  };

  const onUserClick = useCallback(
    (id: number) => () => {
      setSelectedUser(id);
    },
    []
  );

  const userListItem = useCallback(
    (user: User | UnassignedUserType) => {
      return (
        <UserItem
          key={user?.id}
          size={8}
          variant="icon"
          onClick={onUserClick(user?.id)}
          css={{ mt: 0 }}
        >
          <ListItemContainer>
            <HStack gap={2} css={{ overflow: 'hidden' }}>
              <Checkbox checked={user?.id === selectedUser} css={{ minWidth: 15 }} />
              {user?.name === 'Unassign' ? (
                <Box css={{ ml: 8, mr: -2 }}>
                  <Flex justify="start">
                    <HiUserCircle size="25px" />
                  </Flex>
                </Box>
              ) : (
                <Avatar
                  src={user?.attachment?.url}
                  fallback={initials(user?.name || user?.email)}
                  variant="pink"
                  size="assign"
                />
              )}
              <Text css={{ ml: 10 }}>{user?.name || user?.email}</Text>
            </HStack>
          </ListItemContainer>
        </UserItem>
      );
    },
    [onUserClick, selectedUser]
  );

  return (
    <Flex
      css={{
        m: 0,
        position: 'relative',
        flexWrap: 'wrap',
      }}
    >
      <>
        <Dialog open={isOpen} onOpenChange={() => !isOpen}>
          <DialogTrigger asChild={true}>
            <HStack onClick={onOpen}>
              {current && (current?.assigned_user_id === null || !assignedUser) && (
                <IconButton size={isDesktop ? 2 : 1}>
                  <HiUserAdd size={15} />
                </IconButton>
              )}
              {current && !!current?.assigned_user_id && assignedUser && (
                <Button variant={'icon'} size={isDesktop ? 2 : 1}>
                  <Avatar
                    src={assignedUserPhoto}
                    fallback={initials(assignedUser?.name || assignedUser?.email)}
                    variant="pink"
                    size="assign"
                  />
                  {!isSmall && (
                    <Text css={{ ml: 10 }}>
                      {assignedUser?.name || assignedUser?.email}
                    </Text>
                  )}
                </Button>
              )}
            </HStack>
          </DialogTrigger>
          <DialogPortal>
            <DialogContent
              onEscapeKeyDown={onClose}
              onPointerDownOutside={onClose}
              style={{
                minWidth: isDesktop ? '620px' : '350px',
                padding: 0,
              }}
            >
              <VStack gap={2}>
                {/* tags search bar */}
                <Box css={{ position: 'relative' }}>
                  <Box css={{ position: 'absolute', top: '32%', left: '1.5%' }}>
                    <HiSearch />
                  </Box>
                  <Input
                    css={{
                      minHeight: 45,
                      padding: '10px 10px 10px 30px',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    placeholder="Search Users"
                    onChange={(e) => onUserSearch(e.target.value)}
                  />
                </Box>
                <ScrollArea
                  variant="combobox"
                  css={{ maxHeight: 250, overflowY: 'auto', marginTop: 0 }}
                >
                  <VStack>{userList.map(userListItem)}</VStack>
                </ScrollArea>
              </VStack>
              <DialogFooter
                justify="end"
                css={{
                  mt: 0,
                  p: 20,
                  borderTop: '1px solid $slate7',
                }}
              >
                <DialogClose asChild>
                  <Button variant="gray" size={2} css={{ mr: '$1' }} onClick={onClose}>
                    Cancel
                  </Button>
                </DialogClose>
                <DialogClose asChild>
                  <Button onClick={assignConversationUser} size={2}>
                    Assign User
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </DialogPortal>
        </Dialog>
      </>
    </Flex>
  );
};

const ListItemContainer = styled(Flex, {
  fontSize: 13,
  width: '100%',
  cursor: 'pointer',
  minHeight: 52,
  px: '$2',
  py: '$2',
  border: 'none',
  '&:hover': {
    backgroundColor: '$slate2',
  },
});

const UserItem = styled(Button, {
  width: '100%',
  height: '100% !important',
  display: 'flex !important',
});
