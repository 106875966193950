/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { VirtuosoHandle } from 'react-virtuoso';

import { QuickCampaignDialog } from '@/pages/campaigns/v2/quick';
import { createQuickCampaign } from '@/pages/campaigns/v2/quick/api';
import { useUploads } from '@/pages/contacts/uploads/context/UploadContext';
import { CreateUpload } from '@/pages/contacts/uploads/CreateUpload';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import {
  initialCustomDataState,
  useCustomData,
} from '@/pages/settings/organization/data/context/CustomDataContext';
import { useSettings } from '@/pages/settings/organization/general/context/SettingsContext';
import { OptOutOptions } from '@/pages/settings/organization/general/context/types';
import {
  ConfirmationDialogDescription,
  CustomDropdownMenuItem,
  CustomDropdownMenuItemWarning,
} from '@/pages/settings/organization/users/UsersTable';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { DownloadContacts, getContactList } from '@/shared/components/DownloadContacts';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { BulkTagModal } from '@/shared/components/filters/bulkAction/modals/BulkTagModal';
import { RemoveFromSequenceModal } from '@/shared/components/filters/bulkAction/modals/RemoveFromSequenceModal';
import { UnsubscribeContactsFromCampaignModal } from '@/shared/components/filters/bulkAction/modals/UnsubscribeContactsFromCampaignModal';
import { BulkAction, FilteredTable } from '@/shared/components/filters/FiltersTable';
import { prepareFilter } from '@/shared/components/filters/utils';
import { AddToSequenceDialog } from '@/shared/components/modals/AddToSequenceDialog/AddToSequenceDialog';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { ScheduleOptions } from '@/shared/types/campaigns';
import { Channel } from '@/shared/types/channels';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterItem, FilterParams, FilterType, Sort } from '@/shared/types/filter';
import { SequenceBulkActionFilter } from '@/shared/types/sequences';
import { TagBulkActionType } from '@/shared/types/tags';
import { Box } from '@/shared/ui';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';

import { defaultSort, ITEMS_COUNT } from '../contacts';
import { CreateContact } from '../contacts/CreateContact';
import { initialState, useData } from '../context/DataContext';
import { RenameDialog } from '../segments/RenameDialog';
import { applySort } from '../utils/applySort';
import { ContactsTableColumns } from '../utils/ContactsTable';
import {
  handleFilterChange,
  handleInfiniteScroll,
  handleQuickSearch,
  handleSortChange,
} from '../utils/filterActions';
import { sortConfig } from '../utils/filterConfig';
import { prepareFilters } from '../utils/prepareFilters';

export const List = (): JSX.Element => {
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const contacts = useData();

  // the state needed for Quick Campaign
  const location = useChannels();
  const { channelsState } = location;
  const { channels } = channelsState;
  const [currentLocation, setCurrentLocation] = useState<Channel | null>(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedContactIds, setSelectedContactIds] = useState([] as string[]);
  const [totalContactsSelected, setTotalContactsSelected] = useState(0);
  const [isQuickCampaignOpen, setQuickCampaignOpen] = useState(false);
  const [isAddToSequenceModalOpen, setAddToSequenceModalOpen] = useState(false);
  const [isBulkTagModalOpen, setBulkTagModalOpen] = useState(false);
  const [isRemoveFromSequenceModalOpen, setRemoveFromSequenceModalOpen] = useState(false);
  const [isUnsubscribeContactsModalOpen, setUnsubscribeContactsModalOpen] =
    useState(false);
  const [tagModalAction, setTagModalAction] = useState<TagBulkActionType>('unassign');
  const [downloading, setDownloading] = useState(false);
  const { settingsState } = useSettings();
  const { settings } = settingsState;

  const { updateContactFilters, createContact } = contacts;
  const loading = contacts.dataState.loading || false;
  const loadingMore = contacts.dataState.loadingMore || false;

  const data = useMemo(() => {
    const sortField = contacts.dataState.contactsFilters.sort[0]?.column || 'updated_at';
    const sortOrder = contacts.dataState.contactsFilters.sort[0]?.order || 'desc';
    return applySort(contacts.dataState.contacts, sortField, sortOrder);
  }, [contacts.dataState.contacts]);

  const [quickSearchValue, setQuickSearchValue] = useState<string>('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const custom = useCustomData();
  const { getCustomObjects, customDataState } = custom;
  const { customObjects } = customDataState;

  const uploadContext = useUploads();

  const { setCurrentUpload, deleteUpload, updateUpload, getUpload } = uploadContext;
  const { current } = uploadContext.uploadsState;

  useEffect(() => {
    const fetchCustomObjects = async () => {
      await getCustomObjects({
        ...initialCustomDataState.filterParams,
        limit: 100,
        offset: 0,
      });
    };

    if (customObjects.length === 0) fetchCustomObjects();

    // on unmount set the current upload to null
    return () => {
      setCurrentUpload(null);
    };
  }, []);

  const getList = async (id: string) => {
    try {
      getUpload(id);
    } catch (error) {
      history.push('/data/lists');
    }
  };

  // When the param changes get and set an upload from API
  useEffect(() => {
    if (!current || current.id !== params.id) {
      getList(params.id);
    } else if (current.state === 'deleted') {
      history.push('/data/lists');
    }
  }, [params.id, current]);

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => updateContactFilters(props), 1000),
    []
  );

  function combineFilter(
    selectedContactIds: string[],
    searchParam: SearchFilters,
    isAllSelected: boolean
  ): FilterParams {
    const filter = prepareFilter(
      searchParam,
      selectedContactIds,
      isAllSelected,
      'contact'
    );
    return { filter: filter };
  }

  function handleConfirm(
    message: string,
    attachments: Attachments,
    location: Channel,
    scheduleOptions: ScheduleOptions | null
  ) {
    const filter = combineFilter(
      selectedContactIds,
      contacts.dataState.contactsFilters,
      isAllSelected
    );
    const filterParams = {
      filter: filter.filter as FilterItem[],
    };
    createQuickCampaign({
      message,
      attachments,
      location,
      scheduleOptions,
      filter: filterParams,
    });
    setQuickCampaignOpen(false);
  }

  useEffect(() => {
    resetFilters();
  }, [params.id]);

  const resetFilters = () => {
    setQuickSearchValue('');
    setActiveFilters([]);
    updateContactFilters({
      ...initialState.contactsFilters,
      defaultFilter: {
        column: 'id',
        comparison: '==',
        resource: 'list',
        value: params.id,
      },
      sort: defaultSort,
    });
  };

  const tableRef = useRef<VirtuosoHandle>(null);
  const columns = ContactsTableColumns();
  const bulkActions: BulkAction[] = [
    {
      label: 'Add to Sequence',
      action: 'addToSequenceV2',
      title: 'Add to Sequence',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to add **${selected} contact(s)** to an existing sequence?`,
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setAddToSequenceModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setAddToSequenceModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      label: 'Create Campaign',
      action: 'createQuickCampaignV2',
      title: 'Create Quick Campaign',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to add **${selected} contact(s)** to a Quick Campaign?`,
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setQuickCampaignOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setQuickCampaignOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      label: 'Assign Tags',
      action: 'assignTagsV2',
      title: 'Assign Tags',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('assign');
        return `Are you sure you want to assign tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      label: 'Overwrite Tags',
      action: 'overwriteTagsV2',
      title: 'Overwrite Tags',
      type: 'destructive',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('assign.overwrite');
        return `Are you sure you want to overwrite tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      label: 'Un-assign Tags',
      action: 'unassignTagsV2',
      title: 'Un-assign Tags',
      type: 'destructive',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('unassign');
        return `Are you sure you want to unassign tags from ${selected} contact(s)`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      label: 'Remove from Sequence',
      action: 'removeFromSequenceV2',
      title: 'Remove from Sequence',
      type: 'destructive',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to remove **${selected} contact(s)** from an existing sequence`,
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setRemoveFromSequenceModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setRemoveFromSequenceModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      action: 'unsubscribeFromCampaignV2',
      label: 'Un-subscribe',
      title: 'Un-subscribe Contacts from Campaign',
      type: 'destructive',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to unsubscribe **${selected} contact(s)** from an existing Campaign?`,
      onAllSelectedSubmit: () => {
        if (settings.contact_opt_out_default == OptOutOptions.LOCATION)
          setUnsubscribeContactsModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
      onManualSelectedSubmit(selectedContactIds: string[]) {
        if (settings.contact_opt_out_default == OptOutOptions.LOCATION)
          setUnsubscribeContactsModalOpen(true);
        setIsAllSelected(false);
        setSelectedContactIds(selectedContactIds);
        setTotalContactsSelected(selectedContactIds.length);
      },
    },
  ];

  const getContacts = async (isDownloadAll: boolean) => {
    const params = isDownloadAll
      ? prepareFilters({
          ...initialState.contactsFilters,
          defaultFilter: contacts.dataState.contactsFilters.defaultFilter,
          sort: defaultSort,
        })
      : prepareFilters(contacts.dataState.contactsFilters);

    return getContactList(params.filter, params.sort, ITEMS_COUNT);
  };

  return (
    <>
      <Helmet>
        <title>Whippy | List</title>
      </Helmet>
      <PageLayout
        breadcrumbs={[
          { title: 'Data', path: '/data/contacts' },
          { title: 'Lists', path: '/data/lists' },
          { title: `${current?.name || 'List'}`, path: `/data/lists/${params.id}` },
        ]}
        actions={
          <TableActionMenu width={200}>
            <>
              <DownloadContacts
                isSubmenu={true}
                getData={getContacts}
                setDownloading={setDownloading}
                fileName="contacts"
                title={downloading ? 'Downloading Data...' : 'Download CSV'}
              />
              {current && current.id && (
                <RenameDialog
                  title="Rename list"
                  description="Rename this list"
                  value={current}
                  action={updateUpload}
                />
              )}
              <CreateUpload navigateTo="/data/lists/">
                <CustomDropdownMenuItem
                  data-testid="create-contacts-list-option"
                  onClick={(e) => e.preventDefault()}
                >
                  Create Contacts List
                </CustomDropdownMenuItem>
              </CreateUpload>
              <CreateContact handleCreateContact={createContact}>
                <CustomDropdownMenuItem
                  data-testid="add-contact-option"
                  onClick={(e) => e.preventDefault()}
                >
                  Add Contact
                </CustomDropdownMenuItem>
              </CreateContact>
              {current && current.id && (
                <ConfirmationDialog
                  width="432px"
                  title="Delete this list?"
                  description={
                    <ConfirmationDialogDescription
                      value={current.name}
                      description="list will be permanently deleted."
                    />
                  }
                  onConfirm={() => deleteUpload(current.id)}
                  confirmButtonTitle="Confirm"
                  cancelButtonTitle="Cancel"
                  confirmButtonVariant="redBackground"
                  cancelButtonVariant="grayBackground"
                >
                  <CustomDropdownMenuItemWarning
                    data-testid="delete-list-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete list
                  </CustomDropdownMenuItemWarning>
                </ConfirmationDialog>
              )}
            </>
          </TableActionMenu>
        }
      >
        <Box css={{ backgroundColor: 'white', height: '100%', overflowY: 'hidden' }}>
          <Box css={{ padding: 24 }}>
            <CombinedFilters
              quickSearchPlaceholder="Search Contacts"
              quickSearchValue={quickSearchValue}
              setQuickSearchValue={(value: string) => {
                setQuickSearchValue(value);
                handleQuickSearch(
                  debouncedUpdate,
                  contacts.dataState.contactsFilters,
                  value
                );
              }}
              customObjects={customObjects}
              activeFilters={activeFilters}
              setFilters={(value: Array<FilterType>) => {
                setActiveFilters(value);
                handleFilterChange(
                  debouncedUpdate,
                  contacts.dataState.contactsFilters,
                  value
                );
              }}
              sortConfig={sortConfig}
              activeSort={contacts.dataState.contactsFilters.sort}
              onSortUpdate={(value: Array<Sort>) =>
                handleSortChange(
                  updateContactFilters,
                  contacts.dataState.contactsFilters,
                  value
                )
              }
            />
          </Box>
          <Box>
            <FilteredTable
              columns={columns}
              data={data}
              bulkActions={bulkActions}
              isLoading={loading}
              totalCount={contacts.dataState.totalCount}
              onEndReached={() =>
                handleInfiniteScroll(
                  updateContactFilters,
                  contacts.dataState.contactsFilters,
                  data,
                  contacts.dataState.totalCount,
                  loadingMore
                )
              }
              emptyStateElement={<Box>No records found</Box>}
              tableRef={tableRef}
              heightOffset={0}
              showTotalCount
            />
          </Box>
        </Box>
      </PageLayout>
      {isQuickCampaignOpen && (
        <QuickCampaignDialog
          currentLocation={currentLocation}
          setLocation={setCurrentLocation}
          locations={channels}
          isOpen={isQuickCampaignOpen}
          showTimeZonePicker={false}
          setIsOpen={setQuickCampaignOpen}
          handleConfirm={handleConfirm}
          totalContacts={totalContactsSelected}
        />
      )}
      <AddToSequenceDialog
        isOpen={isAddToSequenceModalOpen}
        showTimeZonePicker={false}
        locations={channels}
        totalContacts={totalContactsSelected}
        setIsOpen={setAddToSequenceModalOpen}
        filter={
          combineFilter(
            selectedContactIds,
            contacts.dataState.contactsFilters,
            isAllSelected
          ) as SequenceBulkActionFilter
        }
      />
      <BulkTagModal
        action={tagModalAction}
        open={isBulkTagModalOpen}
        totalContacts={totalContactsSelected}
        onOpenChange={setBulkTagModalOpen}
        filter={
          combineFilter(
            selectedContactIds,
            contacts.dataState.contactsFilters,
            isAllSelected
          ).filter ?? []
        }
      />
      <RemoveFromSequenceModal
        open={isRemoveFromSequenceModalOpen}
        totalContacts={totalContactsSelected}
        onOpenChange={setRemoveFromSequenceModalOpen}
        filter={
          combineFilter(
            selectedContactIds,
            contacts.dataState.contactsFilters,
            isAllSelected
          ).filter ?? []
        }
      />
      <UnsubscribeContactsFromCampaignModal
        onOpenChange={setUnsubscribeContactsModalOpen}
        open={isUnsubscribeContactsModalOpen}
        totalContacts={totalContactsSelected}
        filter={
          combineFilter(
            selectedContactIds,
            contacts.dataState.contactsFilters,
            isAllSelected
          ).filter ?? []
        }
      />
    </>
  );
};
