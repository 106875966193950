import { FilterItem } from '@/shared/types/filter';

export function getFilterItemsByType(
  type: string,
  fullFilter: FilterItem[]
): FilterItem[] {
  if (fullFilter.length == 0) return [];

  if (fullFilter[0].resource == type) {
    // return the filters without any or fields
    return fullFilter.map((filterItem) => cleanedResourceFilterItem(filterItem));
  } else if (fullFilter[0].or) {
    return getFilterItemsByType(type, fullFilter[0].or);
  } else {
    return [];
  }
}

function cleanedResourceFilterItem(filterItem: FilterItem): FilterItem {
  return {
    resource: filterItem.resource,
    column: filterItem.column,
    comparison: filterItem.comparison,
    value: filterItem.value,
  };
}

/**
 * By nature the Quick Filter is a list of or conditions. For example if we select a contact and an upload list,
 * then this means we want all contacts that match the selected contact AND that are in the upload list. However
 * since the we're creating a SQL statement with the WQL then we really need to specify this clause as an or statement.
 * This function will appends a new filter type to an existing filter structure. If the `newFilter` already contains a filter
 * with an `or` condition, it recursively appends the `filterByType` to the existing `or` filter. Otherwise,
 * it adds `filterByType` as a new `or` condition in the `newFilter`.
 *
 * @param newFilter - The array of filter items to which new filter types will be appended.
 * @param filterByType - The new filter type(s) to append to the existing filter structure.
 * @returns - This function modifies the `newFilter` array in place and does not return anything.
 *
 * @example
 * // Example where `filterByType` is appended as a new `or` condition:
 * const newFilter = [
 *   { resource: 'contact', column: 'id', comparison: '==', value: '1' }
 * ];
 *
 * const filterByType = [
 *   { resource: 'contact', column: 'id', comparison: '==', value: '2' }
 * ];
 *
 * appendNewFilterType(newFilter, filterByType);
 *
 * // Resulting `newFilter`:
 * [
 *   { resource: 'contact', column: 'id', comparison: '==', value: '1', or: [
 *     { resource: 'contact', column: 'id', comparison: '==', value: '2' }
 *   ]}
 * ]
 *
 * @example
 * // Example where `newFilter` is empty:
 * const newFilter = [];
 * const filterByType = [
 *   { resource: 'contact', column: 'id', comparison: '==', value: '3' }
 * ];
 *
 * appendNewFilterType(newFilter, filterByType);
 *
 * // Resulting `newFilter`:
 * [
 *   { resource: 'contact', column: 'id', comparison: '==', value: '3' }
 * ]
 */
export function appendNewFilterType(
  newFilter: FilterItem[],
  filterByType: FilterItem[]
): void {
  if (filterByType.length == 0) return;
  // if any of the filters have a mismatch for the resource, throw an error
  if (filterByType.some((filter) => filter.resource != filterByType[0].resource)) {
    throw new Error('All resources must match');
  }
  // if we have no items in the newFilter, have this serve as the root
  else if (newFilter.length == 0) {
    newFilter.push(...filterByType);
  } else if (newFilter[0].or) {
    appendNewFilterType(newFilter[0].or, filterByType);
  } else {
    newFilter[0].or = filterByType;
  }
}

/**
 * Groups an array of filters by the `resource` type, combining them into a single filter object.
 * The function assumes that the `resource` type and `column` are consistent across all filters.
 * If there are no filters or only one filter, it returns the original input or an empty array.
 * If multiple filters are present, it combines them into a single filter with an `in` comparison.
 * NOTE: This expects that the WQL that all the values passed in represent the unique id of the resource.
 *
 * @param filters - An array of filter objects, each containing `resource`, `column`, `comparison`, and `value`.
 * @returns - A new array containing a single grouped filter if there are multiple filters, or the original filters if there's only one.
 *
 * @example
 * // Example input:
 * const filters = [
 *   { resource: 'contact', column: 'id', comparison: '==', value: '1' },
 *   { resource: 'contact', column: 'id', comparison: '==', value: '2' },
 * ];
 *
 * // Example output:
 * [
 *   { resource: 'contact', column: 'id', comparison: 'in', value: ['1', '2'] }
 * ]
 *
 * @example
 * // Input with a single filter:
 * const filters = [
 *   { resource: 'contact', column: 'id', comparison: '==', value: '1' }
 * ];
 *
 * // Output:
 * [
 *   { resource: 'contact', column: 'id', comparison: '==', value: '1' }
 * ]
 */
export function groupFiltersByType(filters: FilterItem[]) {
  if (filters.length == 0) return [];
  else if (filters.length == 1) return filters;
  const groupedFilters: FilterItem[] = [
    {
      resource: filters[0].resource,
      column: 'id',
      comparison: 'in',
      value: filters.map((filter) => filter.value) as string[],
    },
  ];
  return groupedFilters;
}
