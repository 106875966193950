import { useCallback, useState } from 'react';
import { useMedia } from 'react-use';

import { ReportsCollapsible } from '@/shared/components/reports/ReportsCollapsible';
import { Box, Button, Flex, Grid, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { AnalyticsTableType } from '../context/types';
import { AnalyticsTable } from './AnalyticsTable';
import { MapChart } from './MapChart';

export type LocationsSectionProps = {
  /** data to display */
  data: any;
  /** is dating loading */
  loading: boolean;
  /** is response failed */
  error: boolean;
  /** retry request */
  errorCallback?: () => void;
};

const filterItems = ['country', 'city'];

export const LocationsSection = ({
  data,
  loading,
  error,
  errorCallback,
}: LocationsSectionProps) => {
  const [filter, setFilter] = useState('country');
  const isDesktop = useMedia('(min-width: 1200px)');
  const table_config = [
    {
      header: 'Countries',
      tooltip: 'The countries where user click links',
      key: 'key',
    },
    {
      header: 'Total Clicks',
      tooltip: 'Total Clicks',
      key: 'count',
    },
    {
      header: 'Total Unique Clicks',
      tooltip: 'Total Unique Clicks',
      key: 'unique.count',
    },
  ];

  const table_city_config = [
    {
      header: 'Cities',
      tooltip: 'The cities where user click links',
      key: 'key',
    },
    {
      header: 'Total Clicks',
      tooltip: 'Total Clicks',
      key: 'count',
    },
    {
      header: 'Total Unique Clicks',
      tooltip: 'Total Unique Clicks',
      key: 'unique.count',
    },
  ];

  const handleFilterChange = useCallback(
    (filterValue: string) => () => {
      console.log('filterValue', filterValue);
      setFilter(filterValue);
    },
    []
  );

  return (
    <ReportsCollapsible title="Total Clicks by Channel" description="">
      <Grid columns={isDesktop ? '2' : '1'} gap="1">
        <VStack gap={1}>
          <Flex justify="end">
            <ButtonGroup>
              {filterItems.map((item: string) => (
                <TabButton
                  key={item}
                  onClick={handleFilterChange(item)}
                  variant={filter === item ? 'outline' : 'gray'}
                  css={{
                    color: '#10181C',
                    backgroundColor: filter === item ? 'white' : 'transparent',
                    boxShadow: 'none',
                    outline: 'none',
                    textTransform: 'capitalize',
                    height: 35,
                  }}
                  ghost
                  size={1}
                >
                  {item}
                </TabButton>
              ))}
            </ButtonGroup>
          </Flex>
          <AnalyticsTable
            data={filter === 'country' ? data?.by_country : data?.by_city}
            config={filter === 'country' ? table_config : table_city_config}
            sortable
            tableTitle="Total Clicks by Channel"
            loading={loading}
            error={error}
            errorCallback={errorCallback}
            type={
              filter === 'country' ? AnalyticsTableType.COUNTRY : AnalyticsTableType.CITY
            }
          />
        </VStack>
        <Box>
          <MapChart data={data} />
        </Box>
      </Grid>
    </ReportsCollapsible>
  );
};

const ButtonGroup = styled(Flex, {
  fontSize: 12,
  color: '$slate11',
  backgroundColor: '#DFE3EB',
  borderRadius: '$1',
  border: '5px solid #DFE3EB',
});

const TabButton = styled(Button, {
  textTransform: 'capitalize',
  minWidth: 70,
  '&:focus': {
    boxShadow: 'none !important',
  },
  '&:hover': {
    boxShadow: 'none !important',
  },
});
