/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as ToolbarPrimitive from '@radix-ui/react-toolbar';
import React, { useEffect, useState } from 'react';
import {
  HiDuplicate,
  HiExternalLink,
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineTrash,
  HiReply,
  HiTranslate,
  HiX,
} from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { toast } from 'sonner';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { languages } from '@/shared/components/editor/utils';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { TooltipIconButton } from '@/shared/components/TooltipIconButton';
import { useDisclosure } from '@/shared/hooks';
import { ConversationMessageType } from '@/shared/types/conversations';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  Fieldset,
  HStack,
  IconButton,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { styled } from '@/stitches.config';

import { useConversation } from '../../context/ConversationContext';

type MessageToolbarProps = {
  /* the message being hovered over */
  message: ConversationMessageType;
  /* the message to render */
  children: React.ReactNode;
  hoverItemId: string | null;
  mouseEventHandler: (id: string | null) => void;
};

export const MessageToolbar = (props: MessageToolbarProps) => {
  const { children, message, hoverItemId, mouseEventHandler } = props;

  const { toggleForwardState, translateMessage, updateMessage } = useConversation();

  const history = useHistory();

  const auth = useAuth();
  const { isAdmin } = auth;

  // control disclosure of the language panel
  const {
    isOpen: openToolBar,
    onOpen: onOpenToolBar,
    onClose: onCloseToolBar,
  } = useDisclosure();

  useEffect(() => {
    if (message.id === hoverItemId && message.source_type != null) {
      onOpenToolBar();
      setSourceLanguage(message?.language || 'es');
      setTranslationLanguage(message?.translation_language || 'en');
    } else {
      onCloseToolBar();
    }
  }, [hoverItemId]);

  // when the mouse is not over the message area anymore
  // close the toolbar
  const onMouseLeave = () => {
    mouseEventHandler(null);
  };

  // when the mouse is over the message area
  // open the toolbar
  const onMouseEnter = () => {
    mouseEventHandler(message.id);
  };

  // control disclosure for the language panel
  const {
    isOpen: isTranslateOpen,
    onOpen: onTranslateOpen,
    onClose: onTranslateClose,
  } = useDisclosure();

  // control disclosure for the language panel
  const { isOpen, onOpen, onClose } = useDisclosure();
  // the first tool tip open state needs to be controlled
  // in order to prevent flicking caused by the popover
  const [toolTipOpen, _setToolTipOpen] = useState(false);
  // selected languages
  const [sourceLanguage, setSourceLanguage] = useState(message?.language || 'es');
  const [translationLanguage, setTranslationLanguage] = useState(
    message?.translation_language || 'en'
  );

  // using this to display the correct placeholder
  const getLanguageLabel = (value: string) => {
    return languages.find((language) => language.value === value)?.label;
  };

  // clear all states on dialog close
  const onDialogClose = () => {
    onTranslateClose();
  };

  // make a request to translate the message
  // via websocket
  const handleTranslateMessage = () => {
    onDialogClose();
    translateMessage({
      message_id: message?.id,
      source_language: sourceLanguage,
      target_language: translationLanguage,
    });
    setSourceLanguage(sourceLanguage || 'es');
    setTranslationLanguage(translationLanguage || 'en');
  };

  const toggleMessageVisibility = () => {
    const newVisibility = message?.visibility_status === 'hidden' ? 'visible' : 'hidden';
    updateMessage({ message_id: message?.id, visibility_status: newVisibility });
  };

  const deleteMessage = () => {
    updateMessage({ message_id: message?.id, visibility_status: 'removed' });
  };

  // prevent autofocus on popover
  // in order to keep focus on the message editor
  const preventAutoFocus = (event: Event) => {
    event.preventDefault();
  };

  return (
    <Box onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Popover open={openToolBar}>
        <PopoverTrigger asChild>{children}</PopoverTrigger>
        <PopoverContent
          align={message?.source_type === 'INBOUND' ? 'start' : 'end'}
          side="top"
          alignOffset={20}
          onOpenAutoFocus={(event: Event) => preventAutoFocus(event)}
        >
          <StyledToolbar>
            <HStack gap={2}>
              <Tooltip open={toolTipOpen}>
                <TooltipTrigger asChild>
                  <ToolbarPrimitive.ToolbarButton asChild>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(message?.body || '');
                        toast.success(i18next.t('message_copied') as string);
                      }}
                    >
                      <HiDuplicate size={15} />
                    </IconButton>
                  </ToolbarPrimitive.ToolbarButton>
                </TooltipTrigger>
                <TooltipContent side="top" sideOffset={10} asChild>
                  <Box>Copy</Box>
                </TooltipContent>
              </Tooltip>

              <Tooltip>
                <TooltipTrigger asChild>
                  <ToolbarPrimitive.ToolbarButton asChild>
                    <IconButton
                      onClick={() => {
                        const attachmentURLs = message?.attachments
                          ?.map((attachment) => attachment?.url)
                          .filter(Boolean) as string[];

                        const toggleForwardPayload = {
                          message: message.body || '',
                          attachment_urls: attachmentURLs,
                        };

                        toggleForwardState(true, toggleForwardPayload);
                        history.push('/inbox/all/open/new');
                      }}
                    >
                      <HiReply
                        style={{
                          transform: 'rotate(180deg) scaleY(-1)',
                        }}
                      />
                    </IconButton>
                  </ToolbarPrimitive.ToolbarButton>
                </TooltipTrigger>
                <TooltipContent side="top" sideOffset={10}>
                  Forward Message
                </TooltipContent>
              </Tooltip>

              {/* Visibility buttons */}
              {message.visibility_status !== 'removed' && (
                <>
                  {/* show/hide message button */}
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <ToolbarPrimitive.ToolbarButton asChild>
                        <IconButton onClick={() => toggleMessageVisibility()}>
                          {message?.visibility_status === 'hidden' ? (
                            <HiOutlineEye size={15} />
                          ) : (
                            <HiOutlineEyeOff size={15} />
                          )}
                        </IconButton>
                      </ToolbarPrimitive.ToolbarButton>
                    </TooltipTrigger>
                    <TooltipContent side="top" sideOffset={10}>
                      <Box>
                        {message?.visibility_status === 'hidden'
                          ? 'Show Message'
                          : 'Hide Message'}
                      </Box>
                    </TooltipContent>
                  </Tooltip>

                  {/* delete message button and alert dialog confirmation */}
                  {isAdmin && (
                    <>
                      <ConfirmationDialog
                        title="Delete Message"
                        description="Are you sure you want to delete this message? Deleting a message is an irreversible operation."
                        onConfirm={deleteMessage}
                        confirmButtonTitle="Yes, delete"
                        testID="delete-message-button"
                      >
                        <TooltipIconButton
                          sideOffset={10}
                          text="Delete Message"
                          icon={<HiOutlineTrash size={15} />}
                        />
                      </ConfirmationDialog>
                    </>
                  )}
                </>
              )}
              {/* only let users translate the inbound messages for now */}
              {message?.source_type === 'INBOUND' && (
                <Dialog open={isTranslateOpen} modal={false}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <ToolbarPrimitive.ToolbarButton asChild>
                        <IconButton onClick={() => onTranslateOpen()}>
                          <HiTranslate size={16} />
                        </IconButton>
                      </ToolbarPrimitive.ToolbarButton>
                    </TooltipTrigger>
                    <TooltipContent side="top" sideOffset={10}>
                      Translate Message
                    </TooltipContent>
                  </Tooltip>
                  <DialogPortal>
                    <DialogOverlay as="div">
                      <DialogContent
                        onEscapeKeyDown={() => onDialogClose()}
                        onPointerDownOutside={() => onDialogClose()}
                        css={{ zIndex: 99999999 }}
                      >
                        <DialogHeader title="Translate Message" />
                        <VStack gap={1}>
                          <Fieldset>
                            <Label>Translate Message From</Label>
                            <VStack gap={1}>
                              <SingleSelect
                                selectItem={sourceLanguage}
                                setSelectItem={setSourceLanguage}
                                closeOnClick={true}
                                options={languages.map(
                                  (language: { label: string; value: string }) => ({
                                    type: language.label,
                                    value: language.value,
                                  })
                                )}
                                defaultPlaceholder={
                                  getLanguageLabel(sourceLanguage) ||
                                  'Select source language'
                                }
                                isDropdown={true}
                              />
                            </VStack>
                          </Fieldset>
                          <Fieldset>
                            <Label>Translation Message To</Label>
                            <VStack gap={1}>
                              <SingleSelect
                                selectItem={translationLanguage}
                                setSelectItem={setTranslationLanguage}
                                closeOnClick={true}
                                options={languages.map(
                                  (language: { label: string; value: string }) => ({
                                    type: language.label,
                                    value: language.value,
                                  })
                                )}
                                defaultPlaceholder={
                                  getLanguageLabel(translationLanguage) ||
                                  'Select translation language'
                                }
                                isDropdown={true}
                              />
                            </VStack>
                          </Fieldset>
                        </VStack>
                        <DialogFooter justify="end" css={{ mt: 15 }}>
                          <DialogClose asChild>
                            <Button
                              aria-label="Close"
                              variant="gray"
                              css={{ mr: 5 }}
                              type="button"
                              onClick={() => onDialogClose()}
                            >
                              Cancel
                            </Button>
                          </DialogClose>
                          <DialogClose asChild>
                            <Button
                              variant="send"
                              onClick={() => handleTranslateMessage()}
                            >
                              Translated Message
                            </Button>
                          </DialogClose>
                        </DialogFooter>
                        <DialogCloseIcon onClick={() => onDialogClose()} size="2">
                          <HiX size="15px" style={{ color: 'white' }} />
                        </DialogCloseIcon>
                      </DialogContent>
                    </DialogOverlay>
                  </DialogPortal>
                </Dialog>
              )}
              {/* If the message does not contain a campaign then don't show this button */}
              {message?.campaign_id && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <ToolbarPrimitive.ToolbarButton>
                      <IconButton
                        as="a"
                        href={`${window.location.origin}/campaigns/${message.campaign_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <HiExternalLink size={16} />
                      </IconButton>
                    </ToolbarPrimitive.ToolbarButton>
                  </TooltipTrigger>

                  <TooltipContent side="top" sideOffset={10}>
                    View Campaign
                  </TooltipContent>
                </Tooltip>
              )}
            </HStack>
          </StyledToolbar>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

const StyledToolbar = styled(ToolbarPrimitive.Root, {
  display: 'flex',
  padding: 8,
  width: '100%',
  minWidth: 'max-content',
  borderRadius: 6,
  backgroundColor: 'white',
  boxShadow: 'inset 0 0 0 1px $colors$slate5',
});
