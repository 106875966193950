import React, { useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import {
  Box,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemText,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useVoIP } from './context/VoIPContext';

export const VoIPSettings = () => {
  const voip = useVoIP();

  const { device } = voip.voipState;
  const [microphone, setMicrophoneId] = useState<string>(
    device?.audio?.speakerDevices.get().values().next().value.deviceId
  );
  const [speaker, setSpeakerId] = useState<string>('default');

  const mapToArray = (map?: Map<string, MediaDeviceInfo>) =>
    map
      ? Array.from(map, ([name, value]) => ({
          name,
          value,
        }))
      : [];

  return (
    <Box css={{ flex: 1, width: 330, padding: 12 }}>
      <Box css={{ color: 'white', fontSize: 14, fontWeight: 400, pb: 12 }}>
        <Box>Audio Settings</Box>
        <Box css={{ color: '#ADB1B8', mt: 4 }}>Audio Settings</Box>
      </Box>
      <Box css={{ mt: 12 }}>
        <Label css={{ color: 'white' }}>Microphone</Label>
        <Box css={{ margin: '10px 0', width: '100%' }}>
          <Select
            value={microphone}
            onValueChange={(e) => {
              setMicrophoneId(e);
              device?.audio?.speakerDevices.set(e);
            }}
          >
            <Trigger aria-label="option-select-trigger">
              <SelectValue />
              <SelectIcon>
                <HiChevronDown />
              </SelectIcon>
            </Trigger>
            <Content>
              <SelectScrollUpButton>
                <HiChevronUp />
              </SelectScrollUpButton>
              <SelectViewport>
                <SelectGroup>
                  {mapToArray(device?.audio?.availableOutputDevices).map((option) => (
                    <Item key={option.value.deviceId} value={option.value.deviceId}>
                      <SelectItemText>{option.value.label}</SelectItemText>
                    </Item>
                  ))}
                </SelectGroup>
              </SelectViewport>
            </Content>
          </Select>
        </Box>
      </Box>
      <Box css={{ mt: 12 }}>
        <Label css={{ color: 'white' }}>Speaker</Label>
        <Box css={{ margin: '10px 0', width: '100%' }}>
          <Select
            value={speaker}
            onValueChange={(e) => {
              setSpeakerId(e);
              device?.audio?.setInputDevice(e);
            }}
          >
            <Trigger aria-label="option-select-trigger">
              <SelectValue />
              <SelectIcon>
                <HiChevronDown />
              </SelectIcon>
            </Trigger>
            <Content>
              <SelectScrollUpButton>
                <HiChevronUp />
              </SelectScrollUpButton>
              <SelectViewport>
                <SelectGroup>
                  {mapToArray(device?.audio?.availableInputDevices).map((option) => (
                    <Item key={option.value.deviceId} value={option.value.deviceId}>
                      <SelectItemText>{option.value.label}</SelectItemText>
                    </Item>
                  ))}
                </SelectGroup>
              </SelectViewport>
            </Content>
          </Select>
        </Box>
      </Box>
    </Box>
  );
};

export const Trigger = styled(SelectTrigger, {
  width: '100%',
  backgroundColor: 'transparent',
  border: '1px solid #D4E4FE31',
  boxShadow: 'none',
  color: '#EDEEF0',
  fontSize: 14,
  fontWeight: 400,
  '& > span:first-child': {
    maxWidth: 'calc(100% - 20px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const Content = styled(SelectContent, {
  backgroundColor: '#1B1B1F',
  border: '1px solid #D4E4FE31',
});

export const Item = styled(SelectItem, {
  backgroundColor: '#1B1B1F',
  '&[data-state="checked"]': {
    backgroundColor: '$slate3',
    color: '#000',
  },
});

export const Value = styled('span', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
