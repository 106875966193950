import React from 'react';

// import { useAuth } from '@/pages/auth/context/AuthProvider';
// import { useChannels } from '@/pages/settings/locations/context/ChannelContext';
import { Datepicker } from '@/shared/components/datepicker/Datepicker';
import { PageLayout } from '@/shared/layouts/PageLayout';
// import { MultiSelect } from '@/shared/components/MultiSelect';
// import { Channel } from '@/shared/types';
import { Box, HStack } from '@/shared/ui';

import { useReport } from '../context/ReportsContext';

type ReportsPageProps = {
  children: React.ReactNode;
  title: string;
};

export const ReportsContentContainer = (props: ReportsPageProps): JSX.Element => {
  const { title } = props;

  const reports = useReport();
  const { setDates } = reports;

  // const {
  //   channelsState: { locations, allChannels },
  // } = useChannels();

  // const auth = useAuth();

  // is the user an admin?
  // const { isAdmin } = auth

  // if the user is an admin, show all locations, otherwise show only the user's locations
  // const locationsList = isAdmin ? allChannels : locations;

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Reports', path: '/reports' },
        { title: title, path: `/reports/${title.toLowerCase()}` },
      ]}
      actions={
        <HStack align="center">
          {/* {locationsList.length > 0 && (
      <Box css={{ width: 286 }}>
        <MultiSelect
          defaultPlaceholder="Channels"
          defaultSelectedItems={locationsList.map(
            (location: Channel) => location.id
          )}
          isDropdown={true}
          options={locationsList.map((location: Channel) => ({
            type: location?.name || '',
            value: location.id,
          }))}
          setParentSelectedItems={setSelectedLocations}
          isCampaigns={false}
        />
      </Box>
    )} */}
          <Box>
            <Datepicker setParentDates={setDates} />
          </Box>
        </HStack>
      }
    >
      <Box css={{ p: 30 }}>{props.children}</Box>
    </PageLayout>
  );
};
