import dayjs from 'dayjs';
import React, { useState } from 'react';
import { HiPhoneIncoming } from 'react-icons/hi';

import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import {
  ConversationItemModeTypes,
  ConversationMessageType,
} from '@/shared/types/conversations';
import { Box, Button, Flex, HStack, VStack } from '@/shared/ui';
import { TIME_STAMP } from '@/shared/utils/timestamps';
import { styled } from '@/stitches.config';

import { StyledMessageFooter } from '../inbox/conversation/items';
import { ContactIcon } from '../inbox/conversation/items/ContactIcon';

type InboundCallProps = {
  message: ConversationMessageType;
  /* Date of message */
  date?: string | undefined | null;
  /* Name of contact */
  contact_name: string;
};

export const SHORT_BODY_LENGTH = 300;

export const InboundCall = (props: InboundCallProps) => {
  const { message, date, contact_name } = props;
  const { attachments, mode } = message;

  // Filter the attachments objects for content_type audio/x-wav
  const audioAttachments = attachments.filter(
    (attachment) => attachment?.content_type === 'audio/x-wav'
  );

  const shortBody = message.body?.slice(0, SHORT_BODY_LENGTH);

  // Should we show the full text transcript?
  const [showFullBody, setShowFullBody] = useState(false);

  const isCallCompleted = message.delivery_status.includes('call_completed');

  return (
    <Box>
      <Flex justify="start" align="end">
        <ContactIcon contact_name={contact_name} />
        <CallContainer>
          <VStack gap="2">
            <HStack gap="2">
              <HiPhoneIncoming />
              <Box css={{ fontSize: '14px', fontWeight: 'bold' }}>
                {isCallCompleted ? 'Call Ended' : 'Call In-Progress'}
              </Box>
            </HStack>
            {isCallCompleted && message?.body && (
              <>
                <Box css={{ fontSize: '12px' }}>Transcript</Box>
                <Box>
                  {showFullBody
                    ? renderText(message?.body || '')
                    : renderText(shortBody || '')}
                </Box>
                {message.body && message.body.length > SHORT_BODY_LENGTH && (
                  <Button
                    size="1"
                    variant="gray"
                    ghost
                    onClick={() => setShowFullBody(!showFullBody)}
                  >
                    {showFullBody ? 'Show Less' : 'Show More'}
                  </Button>
                )}
                <AudioControl controls src={audioAttachments[0]?.url}>
                  <track kind="captions" />
                  Your browser does not support the audio element.
                </AudioControl>
              </>
            )}
          </VStack>
        </CallContainer>
      </Flex>
      <StyledMessageFooter align="start" justify="start" css={{ ml: 5, mt: 4 }}>
        <Flex align="center">
          <Box css={{ mr: 5 }}>{dayjs(date).format(TIME_STAMP)}</Box>
          <Box css={{ fontWeight: 500 }}>
            {!contact_name && mode === ConversationItemModeTypes.IMPORTED
              ? 'Imported'
              : contact_name}
          </Box>
        </Flex>
      </StyledMessageFooter>
    </Box>
  );
};

const CallContainer = styled(Box, {
  maxWidth: '500px',
  borderRadius: '8px',
  overflow: 'hidden',
  marginBottom: 2,
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 20,
  paddingTop: 20,
  textAlign: 'left',
  lineHeight: 1.4,
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
  fontSize: 14,
  border: '1px solid $slate3',
  backgroundColor: '$slate2 !important',
  ':hover': {
    backgroundColor: '$slate2 !important',
  },
});

const AudioControl = styled('audio', {
  width: '100%',
  outline: 'none',
});
