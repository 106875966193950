import React, { useState } from 'react';
import { HiPhoneOutgoing } from 'react-icons/hi';

import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import { ConversationMessageType } from '@/shared/types/conversations';
import { Box, Button, Flex, HStack, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import {
  MessageError,
  MessageSuccess,
  StyledMessageFooter,
} from '../inbox/conversation/items';
import { SenderIcon } from '../inbox/conversation/items/SenderIcon';
import { SHORT_BODY_LENGTH } from './InboundCall';

type OutboundCallProps = {
  message: ConversationMessageType;
  /* Date of message */
  date?: string | undefined | null;
  /* Name of contact */
  user_email: string;
};

export const OutboundCall = (props: OutboundCallProps) => {
  const { message, date, user_email } = props;
  const { attachments, delivery_status, error, type } = message;

  // Filter the attachments objects for content_type audio/x-wav
  const audioAttachments = attachments.filter(
    (attachment) => attachment?.content_type === 'audio/x-wav'
  );

  const shortBody = message.body?.slice(0, SHORT_BODY_LENGTH);

  // Should we show the full text transcript?
  const [showFullBody, setShowFullBody] = useState(false);

  const isCallCompleted = message.delivery_status.includes('call_completed');

  return (
    <>
      <Flex justify="end" align="end">
        <CallContainer>
          <VStack gap="2">
            <HStack gap="2">
              <HiPhoneOutgoing />
              <Box css={{ fontSize: '14px', fontWeight: 'bold' }}>
                {isCallCompleted ? 'Call Ended' : 'Call In-Progress'}
              </Box>
            </HStack>
            {isCallCompleted && message?.body && (
              <>
                <Box css={{ fontSize: '12px' }}>Transcript</Box>
                <Box>
                  {showFullBody
                    ? renderText(message?.body || '')
                    : renderText(shortBody || '')}
                </Box>
                {message.body && message.body.length > SHORT_BODY_LENGTH && (
                  <Button
                    size="1"
                    variant="gray"
                    ghost
                    onClick={() => setShowFullBody(!showFullBody)}
                    css={{ color: 'white' }}
                  >
                    {showFullBody ? 'Show Less' : 'Show More'}
                  </Button>
                )}
                <AudioControl controls src={audioAttachments[0]?.url}>
                  <track kind="captions" />
                  Your browser does not support the audio element.
                </AudioControl>
              </>
            )}
          </VStack>
        </CallContainer>
        <SenderIcon message={message} />
      </Flex>
      <StyledMessageFooter
        align="end"
        justify="end"
        css={{ alignItems: 'center', mr: 5, mt: 4 }}
      >
        {error && (
          <MessageError
            status={delivery_status}
            error={error}
            date={date}
            user={user_email}
            message_type={type}
          />
        )}
        {!error && (
          <MessageSuccess
            status={status}
            error={error}
            date={date}
            user={user_email}
            message_type={type}
          />
        )}
      </StyledMessageFooter>
    </>
  );
};

const CallContainer = styled(Flex, {
  maxWidth: '500px',
  borderRadius: '8px',
  overflow: 'hidden',
  marginBottom: 2,
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 20,
  paddingTop: 20,
  textAlign: 'left',
  lineHeight: 1.4,
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
  fontSize: 14,
  border: '1px solid $slate3',
  backgroundColor: '$messageColor !important',
  ':hover': {
    backgroundColor: '$messageColor !important',
  },
  color: '#ffffff',
});

const AudioControl = styled('audio', {
  width: '100%',
  outline: 'none',
});
