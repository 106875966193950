/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { DatePicker } from 'react-dayjs-picker';
import { HiChevronDown, HiOutlineClock, HiX } from 'react-icons/hi';
import { toast } from 'sonner';

import { convertTime12to24, convertTime24to12 } from '@/campaigns/context/utils';
import { useConversation } from '@/pages/inbox/context/ConversationContext';
import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Fieldset,
  IconButton,
  Label,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';

import { TimePicker } from '../../timepicker/TimePicker';
import { TimezonePicker } from '../../timezonepicker/TimezonePicker';
import { ICON_SIZE } from '../v2';
import { MessageEditorV2 } from '.';
import { Attachments } from './constants';
import { useFocusedContext } from './context/FocusedContext';
import { isDateInThePast, isTimeInThePast } from './utils';

export type ScheduleParams = {
  day: string;
  month: string;
  year: string;
  hour: string;
  minute: string;
  timezone: string;
};

type ScheduleMessageProps = {
  /**  schedule message params */
  scheduleParams: ScheduleParams;
  /**  set schedule message params */
  setScheduleParams: (params: ScheduleParams) => void;
  /**  enable/disable schedule button */
  disableSendScheduledMessage?: boolean;
  /**  call on schedule message send */
  sendScheduledMessage: (() => void) | ((params: ScheduleParams) => void);
  /**  body of the message as a Slate object */
  message: string;
  /**  set message body function */
  setMessage: Dispatch<SetStateAction<string>>;
  /**  attachments */
  attachments?: Attachments;
  /**  set attachments */
  setAttachments?: Dispatch<SetStateAction<Attachments>>;
};

export function ScheduleMessageV2(props: ScheduleMessageProps): JSX.Element {
  const {
    scheduleParams,
    setScheduleParams,
    disableSendScheduledMessage,
    sendScheduledMessage,
    message,
    setMessage,
    attachments,
    setAttachments,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { toggleForwardState } = useConversation();

  const focused = useFocusedContext();
  const { setScheduledModalActive } = focused;

  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);

  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone ?? ''
  );

  const [date, setDate] = useState<string>(dayjs(new Date()).format('MM/DD/YYYY'));
  const [time, setTime] = useState('');
  const [open, setOpen] = useState(false);

  // this fixes bug where datepicker is not working while a drawer is open
  useEffect(() => {
    const bodyElement = document.getElementsByTagName('body')[0];
    if (isOpen && bodyElement.style.pointerEvents === 'none') {
      bodyElement.style.pointerEvents = 'auto';
    }
  }, [isOpen]);

  useEffect(() => {
    if (time) {
      const [month, day, year] = date.split('/');
      const [hour, minutePart] = time.split(':');
      const [minute, ampm] = minutePart.split(' ');

      const convertedHour =
        ampm === 'PM'
          ? hour === '12'
            ? '12'
            : (Number(hour) + 12).toString()
          : hour === '12'
            ? '00'
            : hour;

      const updatedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      setScheduleParams({
        day,
        month,
        year,
        hour: convertedHour,
        minute,
        timezone: timezone || updatedTimezone,
      });
    }
  }, [time, date, timezone, isOpen]);

  const dateSelect = (date: dayjs.Dayjs) => {
    const dateFormatted = dayjs(date).format('MM/DD/YYYY');
    setDate(dateFormatted);
  };

  const handleSend = () => {
    // Verify that the user has selected a valid time
    const scheduledTime = convertTime24to12(
      `${scheduleParams?.hour}:${scheduleParams?.minute}`
    );
    if (scheduledTime === 'Invalid Date') {
      return toast.error('Please select a valid time for your scheduled message.');
    }

    sendScheduledMessage(scheduleParams);
    handleClose();

    // Reset forward state as it has been used
    toggleForwardState(false, { message: '', attachment_urls: [], signature: null });
  };

  const isScheduledMessageValid = () => {
    if (!isOpen) return;

    // is the message or attachments are empty
    if (!message && (!attachments || attachments?.attachment_urls.length === 0)) {
      return false;
    }

    // is the timezone set
    if (!timezone) {
      return false;
    }

    // is the date in the past
    const todaysDate = dayjs(
      new Date().toLocaleString('en-US', {
        timeZone: timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    ).format('MM/DD/YYYY');

    if (isDateInThePast(date, todaysDate)) {
      return false;
    }

    // is the time in the past
    const todaysTime = dayjs(
      new Date().toLocaleString('en-US', {
        timeZone: timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    ).format('H:mm');
    if (isTimeInThePast(convertTime12to24(time), todaysTime) && date === todaysDate) {
      return false;
    }

    return true;
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleTranslateMessage = (
    _: string,
    translatedMessage: string,
    attachmentUrls: string[]
  ) => {
    setMessage(translatedMessage);
    setAttachments &&
      setAttachments((prev) => ({ ...prev, attachment_urls: attachmentUrls }));
  };

  const handleOpen = () => {
    setScheduledModalActive(true);
    onOpen();
  };

  const handleClose = () => {
    onClose();
    setScheduledModalActive(false);
    setScheduleParams({
      day: '',
      month: '',
      year: '',
      hour: '',
      minute: '',
      timezone: '',
    });
  };

  return (
    <Dialog open={isOpen} modal={false}>
      <Tooltip>
        <TooltipTrigger asChild>
          <DialogTrigger asChild>
            <IconButton
              size={2}
              onClick={handleOpen}
              disabled={disableSendScheduledMessage}
              aria-label="schedule-button"
            >
              <HiOutlineClock size={ICON_SIZE} />
            </IconButton>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent side="top">
          {'Schedule Message'}
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent
            onEscapeKeyDown={handleClose}
            onPointerDownOutside={handleClose}
            css={{ zIndex: 99999999 }}
          >
            <DialogTitle css={{ fontSize: 20, fontWeight: 700, marginBottom: 16 }}>
              Create Scheduled Message
            </DialogTitle>
            <VStack gap={1}>
              <Fieldset>
                <Label htmlFor="date">Select Timezone</Label>
                <Box>
                  <TimezonePicker timezone={timezone} setTimezone={setTimezone} />
                </Box>
              </Fieldset>
              <Fieldset>
                <Label htmlFor="date">Select Date</Label>
                <Box css={{ position: 'relative' }}>
                  <HiChevronDown
                    style={{
                      position: 'absolute',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      right: '10px',
                      pointerEvents: 'none',
                      fontSize: '16px',
                    }}
                  />
                  <DatePicker
                    isOpen={open}
                    setIsOpen={setOpen}
                    zIndex={999999999999999}
                    value={dayjs(date).format('ddd MMM D')}
                    markToday={true}
                    format="MMM-dd-yyyy"
                    disableBeforeToday={true}
                    inputStyle={{
                      padding: '2px 10px',
                      width: '450px',
                      height: '35px',
                      border: '1px solid #D7DBDF',
                      borderRadius: '4px',
                      fontSize: '13px',
                      cursor: 'pointer',
                      textAlign: 'left',
                      pointerEvents: 'all',
                      backgroundColor: 'transparent',
                      color: '#000000',
                      outlineColor: '#3E54CF',
                    }}
                    colors={{
                      highlighted: 'hsl(0 0% 90.9%)',
                      disabled: 'hsl(0 0% 78.0%',
                      default: '',
                      active: '',
                    }}
                    onSelect={dateSelect}
                    popoverPositions={['bottom']}
                    closeOnSelect={true}
                  />
                </Box>
              </Fieldset>
              <Fieldset>
                <Label htmlFor="time">Select Time</Label>
                <TimePicker
                  setTime={setTime}
                  time={
                    convertTime24to12(
                      `${scheduleParams?.hour}:${scheduleParams?.minute}`
                    ) || time
                  }
                  currentDate={date}
                  timezone={timezone || Intl.DateTimeFormat().resolvedOptions().timeZone}
                  disabled={false}
                  variant="large"
                />
              </Fieldset>
              <Fieldset>
                <Label>Message</Label>
                <MessageEditorV2
                  textareaRef={textareaRef}
                  source="schedule.message.editor"
                  setMessage={setMessage}
                  message={message}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  showCharacterCount={true}
                  showAddTemplate={true}
                  showAddEmoji={true}
                  showAddTranslate={true}
                  translateMessageAction={handleTranslateMessage}
                  showAddAttachment={true}
                  attachmentLoading={attachmentLoading}
                  setAttachmentLoading={setAttachmentLoading}
                  enableAttachments={true}
                />
              </Fieldset>
            </VStack>
            <DialogFooter justify="end">
              <DialogClose asChild>
                <Button
                  aria-label="Close"
                  variant="gray"
                  css={{ mr: 5 }}
                  type="button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button
                  aria-label="Schedule Message"
                  onClick={handleSend}
                  disabled={
                    props.disableSendScheduledMessage || !isScheduledMessageValid()
                  }
                >
                  Schedule Message
                </Button>
              </DialogClose>
            </DialogFooter>
            <DialogCloseIcon onClick={handleClose} size="2">
              <HiX size="15px" style={{ color: 'white' }} />
            </DialogCloseIcon>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
}
