import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { WhippyLogo } from '@/shared/components/Icons';
import { ConversationMessageType } from '@/shared/types/conversations';
import { Avatar, Box } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';

type SenderIconProps = {
  message: ConversationMessageType;
};

export const SenderIcon = (props: SenderIconProps) => {
  const { message } = props;
  const { userState } = useUsers();
  const { users } = userState;

  // get the user object from the message.user_id if it exists
  const user = users.find((u) => u.id === message.user_id);

  return (
    <>
      {user && (
        <Box css={{ ml: 8 }}>
          <Avatar
            src={user?.attachment?.url}
            fallback={initials(user?.name || 'J')}
            size="sender"
            variant="lightGray"
          />
        </Box>
      )}
      {!user && (
        <Box css={{ ml: 8, mr: '0 !important' }}>
          <WhippyLogo />
        </Box>
      )}
    </>
  );
};
