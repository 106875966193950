import { FilterParams } from '@/shared/types/filter';

import { Audience } from '../audience';
import { ConversationMessageType } from '../conversations';
import { Trigger } from '../triggers';
import { User } from '../users';

export type Campaign = {
  /** campaign uuid */
  id?: string;
  /** campaign title */
  title: string | null;
  /** campaign status */
  status: CampaignStatus;
  /** campaign message body */
  body: string | null;
  /** campaign message attachments */
  attachment_urls: Array<string>;
  /** campaign audience */
  audience?: Audience | null;
  /** new campaign audience  */
  included_audience_filter?: FilterParams | null;
  excluded_audience_filter?: FilterParams | null;
  type?: 'sms' | 'email';
  /** channel id (aka location) */
  channel_id?: string | null;
  /** campaign exclude contacts */
  excluded_contacts?: CampaignExcludeContacts | null;
  /** campaign schedule options */
  schedule_options: ScheduleOptions | null;
  /** campaign created by user */
  created_by?: User | null;
  /** campaign last updated by user */
  updated_by?: User | null;
  /** campaign analytics */
  analytics?: CampaignAnalytics | null;
  /** campaign triggers */
  triggers?: Array<Trigger> | null;
  /** campaign delivery options */
  delivery_options?: DeliveryOptions | null;
  /** campaign settings */
  settings: CampaignSettings | null;
  /** when the campaign was created */
  inserted_at?: string | null;
  /** when the campaign was last updated */
  updated_at?: string | null;
  /** Campaign permissions */
  access_level?: CampaignPermissionTypes | null;
  /** Campaign permission locations */
  location_ids?: Array<string> | null;
  /** Automation template ids under this campaign */
  automation_template_ids?: Array<string> | null;
  /** Campaign template associations */
  associated_automation_templates?: Array<{
    automation_template_id: string;
    campaign_automation_template_id: string; // this is the id of the association
  }> | null;
  /** organization id of this campaign */
  organization_id?: string | null;
  /** industry campaign belongs to */
  industry?: string | null;
};

export enum CampaignStatus {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  ARCHIVED = 'archived',
  PAUSED = 'paused',
  QUEUED = 'queued',
  FAILED = 'failed',
  TEMPLATE = 'template',
  DELETED = 'deleted',
}

export const CampaignIndustryOptions = [
  {
    type: 'Staffing',
    value: 'Staffing',
  },
  {
    type: 'Dental',
    value: 'Dental',
  },
  {
    type: 'Medical',
    value: 'Medical',
  },
  {
    type: 'Roofing',
    value: 'Roofing',
  },
  {
    type: 'Real Estate',
    value: 'Real Estate',
  },
  {
    type: 'Home Services',
    value: 'Home Services',
  },
  {
    type: 'Beauty',
    value: 'Beauty',
  },
  {
    type: 'Sales',
    value: 'Sales',
  },
  {
    type: 'Marketing',
    value: 'Marketing',
  },
  {
    type: 'Legal',
    value: 'Legal',
  },
  {
    type: 'Other',
    value: 'Other',
  },
];

export type DeliveryOptions = {
  /* number of messages to send in a batch */
  messages_per_period: number | null;
  /* time between batches seconds */
  period: number | null;
};

export type ScheduleOptions = {
  /* the day of the month */
  day: string;
  /* the month (note that this is not indexed like the Date module and dayjs) */
  month: string;
  /* the year */
  year: string;
  /* the hour */
  hour: string;
  /* the minute */
  minute: string;
  /* the timezone */
  timezone: string;
  /* the campaign batch sending rate */
  rate_limit?: {
    /* seconds */
    period: number | null;
    /* number of messages */
    messages_per_period: number | null;
  };
};

export type CampaignExcludeContacts = {
  /* exclude contacts who have received a campaign in the last x days */
  time_since_last_campaign?: number;
  /* exclude contacts who have an open conversation status */
  exclude_contacts_with_open_conversations?: boolean;
} & Audience;

export type CampaignAnalytics = {
  /* the number of contacts who have received the campaign */
  campaign_progress: number;
  /* the number of messages with confirmed delivery */
  delivery_rate: number;
  /* the number of messages that failed to send */
  failed_deliveries: number;
  /* the number of contacts who clicked a link in the message */
  link_clicks: number;
  /* the % of contacts who clicked a link in the message */
  link_clicks_rate: number;
  /* the % of contacts who responded */
  response_rate: number;
  /* the number of contacts who responded */
  responses: number;
  /* the number of messages that were sent */
  sent_messages: number;
  /* the number of messages to be sent */
  slated_messages: number;
  /* the % of contacts that unsubscribed */
  unsubscribe_rate: number;
  /* the number of contacts who unsubscribed */
  unsubscribes: number;
  /* the number of messages not sent */
  unfulfilled_rate: number;
};

export type CampaignSettings = {
  /* send during quiet hours? */
  support_sms_quiet_hours: {
    enabled: boolean;
    use_contact_timezone: boolean;
  };
  /* skip sending on weekends? */
  skip_sending_on_weekend: {
    enabled: boolean;
    use_contact_timezone: boolean;
  };
  /* only send during business hours? */
  only_send_during_business_hours: {
    enabled: boolean;
    use_contact_timezone: boolean;
  };
  /* campaign link tracking settings */
  link_tracking: CampaignLinkTrackingSettings;
};

export type CampaignLinkTrackingSettings = {
  disabled: boolean;
  domain_id: string | null;
};

export type CampaignContact = {
  /* campaign contact uuid */
  id: string;
  /* the message sent to the contact */
  campaign_message: ConversationMessageType;
  /* array of contact messages */
  contact_messages: Array<ConversationMessageType>;
  /* the contact object as a preview */
  contact: Contact;
  /* campaign contact added */
  inserted_at: string;
  /* campaign contact updated */
  updated_at: string;
};

export type Contact = {
  /* contact uuid */
  id: string;
  /* contact full name */
  name: string | null;
  /* contact email */
  email: string | null;
  /* contact phone */
  phone: string;
  /* contact source */
  source: string | null;
  /* contact source */
  language: string;
};

export enum CampaignPermissionTypes {
  USER = 'user',
  LOCATION = 'location',
  ORGANIZATION = 'organization',
  GLOBAL = 'global',
}

// campaign accordion types
export enum AccordionValue {
  SELECT_LOCATION = 'select_location',
  SELECT_SCHEDULE = 'select_schedule',
  SELECT_AUDIENCE = 'select_audience',
  CREATE_MESSAGE = 'create_message',
  CREATE_AUTOMATION = 'create_automation',
  DEFAULT_VALUE = '',
}

// campaign panel types
export enum PanelType {
  VIEW_AUDIENCE = 'view_audience',
  VIEW_EXCLUDED_AUDIENCE = 'view_excluded_audience',
  VIEW_PREVIEW = 'view_preview',
  VIEW_HELP = 'view_help',
  SUGGESTIONS = 'suggestions',
}

// type of object with unknown keys and an array
// of contact objects as values
export type AudienceContacts = {
  [key: string]: Array<Contact> | Array<null>;
};

// campaign audience object type
export type AudiencePreviewType = {
  audience: AudienceContacts | Array<null>;
  excluded_contacts: AudienceContacts | Array<null>;
  audience_count?: number;
};

// campaign Batch Sending Delay time units
export enum BatchDelayTimeUnit {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}
