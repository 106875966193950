/* eslint-disable react-hooks/exhaustive-deps */
import * as SelectPrimitive from '@radix-ui/react-select';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { HiChevronDown, HiChevronUp, HiX } from 'react-icons/hi';
import { useMedia } from 'react-use';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { convertTime24to12 } from '@/pages/campaigns/context/utils';
import {
  FormFieldWrapper,
  FormMessageEditorV2,
  TextInput,
} from '@/shared/components/forms';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { useDisclosure } from '@/shared/hooks';
import { Template, TemplateContentType } from '@/shared/types/templates';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Fieldset,
  Flex,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@/shared/ui';
import { Switch, SwitchThumb } from '@/shared/ui/Switch';

import { useChannels } from '../channels/context/ChannelContext';
import { messageTemplatePermissions } from './constants';
import { useTemplates } from './context/TemplatesContext';
import { ScheduleTemplate } from './ScheduleTemplate';

type UpdateTemplateProps = {
  template: Template;
  children: React.ReactNode;
};

const initialScheduledTemplateParams = {
  days_in_the_future: 0,
  hour: 0,
  minute: 0,
  timezone: '',
};

export const UpdateTemplate = (props: UpdateTemplateProps): JSX.Element => {
  const templatesContext = useTemplates();
  const { editTemplate, deleteTemplate } = templatesContext;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const authContext = useAuth();
  const { isAdmin } = authContext;

  const locationContext = useChannels();
  const { channels, allChannels } = locationContext.channelsState;
  const [permissionValue, setPermissionValue] = useState(props.template.access_level);
  const [selectedLocations, setSelectedLocations] = useState({
    locations: props.template.locations?.map((location) => location.id) || [],
  });
  const [isReviewRequest, setIsReviewRequest] = useState(
    props.template.content_type === TemplateContentType.REVIEW_REQUEST
  );
  const [isReviewResponse, setIsReviewResponse] = useState(
    props.template.content_type === TemplateContentType.REVIEW_RESPONSE
  );
  const [isScheduledTemplate, setIsScheduledTemplate] = useState(
    props.template.is_scheduled_template
  );
  const [scheduledTemplateParams, setScheduledTemplateParams] = useState(
    props.template.is_scheduled_template
      ? props.template.scheduled_template_params
      : initialScheduledTemplateParams
  );

  // set the date from the schedule params if it exists
  // otherwise set the date to the current date
  const [days, setDays] = useState<number>(
    props.template?.scheduled_template_params?.days_in_the_future
      ? props.template?.scheduled_template_params?.days_in_the_future
      : 0
  );

  // set the time from the schedule params if it exists
  // if it doesn't exist, set the time to the current time
  const [time, setTime] = useState(
    props.template?.scheduled_template_params?.hour &&
      props.template?.scheduled_template_params?.minute
      ? convertTime24to12(
          `${props.template?.scheduled_template_params?.hour}:${props.template?.scheduled_template_params?.minute}`
        )
      : ''
  );

  // set the timezone from the schedule params if it exists
  // or use the default browser timezone
  const [time_zone, setTimezone] = useState(
    props.template?.scheduled_template_params?.timezone
      ? props.template?.scheduled_template_params?.timezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const isDesktop = useMedia('(min-width: 620px)');

  // disable submit btn if location permission is selected without picking location/s
  const shouldDisableSubmitBtn =
    permissionValue === 'location' && selectedLocations.locations.length < 1;

  const handleDelete = (template: Template) => {
    deleteTemplate(template);
    onClose();
  };

  useEffect(() => {
    if (time) {
      let hour = time?.split(':')[0] || '';
      const minute = time?.split(':')[1]?.split(' ')[0] || '';
      const ampm = time?.split(' ')[1] || '';

      if (ampm === 'PM') {
        if (hour === '12') {
          hour = '12';
        } else {
          hour = (Number(hour) + 12).toString();
        }
      } else {
        if (hour === '12') {
          hour = '00';
        }
      }

      setScheduledTemplateParams({
        days_in_the_future: days,
        hour: Number(hour),
        minute: Number(minute),
        timezone: time_zone,
      });
    }
  }, [time, days, time_zone]);

  return (
    <Dialog open={isOpen} onOpenChange={() => !isOpen}>
      <DialogTrigger asChild onClick={() => onOpen()}>
        {props.children}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay css={{ zIndex: 999999 }}>
          <DialogContent
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
            onPointerDownOutside={onClose}
            onEscapeKeyDown={onClose}
            css={{ zIndex: 99999999, width: isDesktop ? 620 : '95%' }}
          >
            <DialogTitle css={{ fontSize: 20, fontWeight: 700, marginBottom: 16 }}>
              Update Template
            </DialogTitle>
            <Formik
              enableReinitialize
              initialValues={{
                title: props.template.title || '',
                message: {
                  body: props.template.message,
                  attachment_urls: props.template.attachments?.map((att) => att.url),
                },
              }}
              validationSchema={Yup.object({
                title: Yup.string().test(
                  'len',
                  'Must be at least 3 characters',
                  (val) => val !== undefined && val.length > 2
                ),
              })}
              onSubmit={async (values: { title: string; message: any }) => {
                const newTemplate = {
                  id: props.template.id,
                  title: values.title,
                  message: values.message.body,
                  attachments: values.message.attachment_urls,
                  access_level: permissionValue,
                  content_type: isReviewRequest
                    ? TemplateContentType.REVIEW_REQUEST
                    : isReviewResponse
                      ? TemplateContentType.REVIEW_RESPONSE
                      : TemplateContentType.GENERAL,
                  is_scheduled_template: isScheduledTemplate,
                  scheduled_template_params: isScheduledTemplate
                    ? scheduledTemplateParams
                    : {},
                };

                if (permissionValue === 'location') {
                  Object.assign(newTemplate, {
                    location_ids: selectedLocations.locations,
                  });
                }

                try {
                  if (
                    values.message.body.length < 1 &&
                    newTemplate.attachments.length < 1
                  ) {
                    return toast.error('Please add at least one attachment or message');
                  }
                  editTemplate(newTemplate as unknown as Template);
                  onClose();
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <FormFieldWrapper
                    label="Template Name"
                    tooltip="The name of your template."
                    name="title"
                  >
                    <TextInput placeholder="Example: New Customer Question" />
                  </FormFieldWrapper>
                  <FormFieldWrapper
                    label="Template Message"
                    tooltip="The message of your template."
                    name="message"
                  >
                    <FormMessageEditorV2
                      source="update-template" // Required for attachments to ensure they are uploaded to the correct Message Editor
                      placeholder="Type your message here..."
                      field="message"
                      showAddAttachment={true}
                      enableAttachments={true}
                      showCharacterCount={true}
                      showAddEmoji={true}
                      showAddReview={true}
                      showAddVariable={true}
                    />
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Permission" name="permission">
                    <Select
                      value={permissionValue}
                      onValueChange={(e) => setPermissionValue(e)}
                    >
                      <SelectTrigger aria-label="option-select-trigger">
                        <SelectValue />
                        <SelectIcon>
                          <HiChevronDown />
                        </SelectIcon>
                      </SelectTrigger>
                      <SelectPrimitive.Portal>
                        <SelectContent css={{ zIndex: 9999999 }}>
                          <SelectScrollUpButton>
                            <HiChevronUp />
                          </SelectScrollUpButton>
                          <SelectViewport>
                            <SelectGroup>
                              <SelectLabel>Permission</SelectLabel>
                              {messageTemplatePermissions.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                  <SelectItemIndicator />
                                  <SelectItemText>{option.label}</SelectItemText>
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectViewport>
                        </SelectContent>
                      </SelectPrimitive.Portal>
                    </Select>
                  </FormFieldWrapper>
                  {permissionValue === 'location' && (
                    <FormFieldWrapper label="Channels" name="locations">
                      {channels.length > 0 && (
                        <MultiSelect
                          defaultPlaceholder="Channels"
                          defaultSelectedItems={props.template.locations?.map(
                            (location) => location.id
                          )}
                          isDropdown={true}
                          options={
                            isAdmin
                              ? allChannels.map((location: any) => ({
                                  type: location?.name || '',
                                  value: location.id,
                                }))
                              : channels.map((location: any) => ({
                                  type: location?.name || '',
                                  value: location.id,
                                }))
                          }
                          parentSelectedItems={selectedLocations}
                          setParentSelectedItems={setSelectedLocations}
                          isCampaigns={true}
                        />
                      )}
                    </FormFieldWrapper>
                  )}
                  <Fieldset>
                    <Flex justify="between" align="center">
                      <Label css={{ marginBottom: 0 }}>
                        Set Template as Review Request
                      </Label>
                      <Switch
                        checked={isReviewRequest}
                        onCheckedChange={(e) => {
                          setIsReviewRequest(e);
                          !!e && setIsReviewResponse(!e);
                        }}
                        aria-label="review-request-template-switch-button"
                      >
                        <SwitchThumb />
                      </Switch>
                    </Flex>
                  </Fieldset>
                  <Fieldset>
                    <Flex justify="between" align="center">
                      <Label css={{ marginBottom: 0 }}>
                        Set Template as Review Response
                      </Label>
                      <Switch
                        checked={isReviewResponse}
                        onCheckedChange={(e) => {
                          setIsReviewResponse(e);
                          !!e && setIsReviewRequest(!e);
                        }}
                        aria-label="review-response-template-switch-button"
                      >
                        <SwitchThumb />
                      </Switch>
                    </Flex>
                  </Fieldset>
                  <Fieldset>
                    <Flex justify="between" align="center">
                      <Label css={{ marginBottom: 0 }}>Schedule Template</Label>
                      <Switch
                        checked={isScheduledTemplate}
                        onCheckedChange={setIsScheduledTemplate}
                        aria-label="schedule-template-switch-button"
                      >
                        <SwitchThumb />
                      </Switch>
                    </Flex>
                  </Fieldset>
                  {isScheduledTemplate && (
                    <Box
                      css={{
                        mt: 10,
                        p: 16,
                        backgroundColor: '#F6F6F6',
                        borderRadius: 4,
                      }}
                    >
                      <Box css={{ p: 0, m: 0 }}>
                        <ScheduleTemplate
                          days={days}
                          isScheduled={isScheduledTemplate}
                          setDays={setDays}
                          time={time}
                          setTime={setTime}
                          timezone={time_zone}
                          setTimezone={setTimezone}
                        />
                      </Box>
                    </Box>
                  )}
                  <DialogFooter justify="end" css={{ mt: 15 }}>
                    <DialogClose asChild>
                      <Button
                        variant="red"
                        css={{ marginRight: '$1' }}
                        onClick={() => handleDelete(props.template)}
                      >
                        Delete Template
                      </Button>
                    </DialogClose>
                    <DialogClose>
                      <Button type="submit" disabled={shouldDisableSubmitBtn}>
                        Update Template
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogClose asChild>
              <DialogCloseIcon onClick={onClose} size="2">
                <HiX style={{ color: 'white' }} />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
