/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch } from 'react';
import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { toast } from 'sonner';

import { useAuth } from '@/auth/context/AuthProvider';
import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import * as ChannelsAPI from '@/shared/api/channels';
import {
  Channel,
  ChannelActions,
  ChannelActionTypes,
  ChannelsStates,
} from '@/shared/types/channels';
import { SearchFilters } from '@/shared/types/contacts';

import { ChannelReducer } from './ChannelReducer';

export type ChannelsState = {
  /** Channels in state */
  channels: Array<Channel>;
  /** Filtered Channels in state */
  filteredChannels: Array<Channel>;
  /** All Channels */
  allChannels: Array<Channel>;
  /** Loading state */
  loading: boolean;
  /** Current Channel state*/
  current: Channel | null;
  /** Filtered Channels state */
  filtered: Array<Channel> | null;
  /** Error state */
  error: Error | null;
  totalCount: number;
  filterParams: SearchFilters;
};

export const PAGE_SIZE = 10;

export const initialChannelsState: ChannelsState = {
  channels: [],
  filteredChannels: [],
  allChannels: [],
  current: null,
  filtered: null,
  error: null,
  loading: true,
  totalCount: 0,
  filterParams: {
    offset: 0,
    limit: PAGE_SIZE,
    sort: [
      {
        label: 'Updated At',
        column: 'updated_at',
        order: 'desc',
        resource: 'location',
        id: null,
      },
    ],
    filter: [],
    searchFilter: [],
    defaultFilters: [
      {
        column: 'state',
        comparison: '==',
        resource: 'location',
        value: 'enabled',
      },
    ],
    defaultFilter: {
      column: 'state',
      comparison: '!=',
      resource: 'location',
      value: 'archived',
    },
  },
};

export const ChannelContext = createContext<{
  channelsState: ChannelsState;
  getChannel: (id: string) => void;
  getUserChannels: (params: { user_id?: number }) => Promise<Channel[] | undefined>;
  getAllOrganizationChannels: () => Promise<void>;
  addChannel: (channel: Partial<Channel>) => Promise<void>;
  updateChannel: (channel: Channel) => Promise<void>;
  disableChannel: (channel: Channel) => Promise<void>;
  setCurrent: (channel: Channel | null) => void;
  clearCurrent: () => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  // pipe fields for google
  name?: string;
  address?: string;
  google_place_id?: string;
  getChannelById: (id: string) => Channel;
  searchChannels: (params: SearchFilters) => void;
  updateFilterParams: (params: SearchFilters) => void;
  enableChannel: (channel: Channel) => Promise<void>;
  archiveChannel: (channel: Channel) => Promise<void>;
}>({
  channelsState: initialChannelsState,
  getChannel: () => Promise.resolve(),
  addChannel: () => Promise.resolve(),
  setCurrent: () => Promise.resolve(),
  clearCurrent: () => Promise.resolve(),
  updateChannel: () => Promise.resolve(),
  disableChannel: () => Promise.resolve(),
  getUserChannels: () => Promise.resolve(undefined),
  getAllOrganizationChannels: () => Promise.resolve(),
  isOpen: false,
  onOpen: () => Promise.resolve(),
  onClose: () => Promise.resolve(),
  getChannelById: () => ({}) as Channel,
  updateFilterParams: () => Promise.resolve(),
  searchChannels: () => Promise.resolve(),
  enableChannel: () => Promise.resolve(),
  archiveChannel: () => Promise.resolve(),
});

export const useChannels = () => useContext(ChannelContext);

const ChannelState = ({ children }: { children: React.ReactNode }) => {
  const [channelsState, dispatch]: [ChannelsState, Dispatch<ChannelActions>] = useReducer(
    ChannelReducer,
    initialChannelsState
  );

  const auth = useAuth();

  // Fetch channels on mount
  useEffect(() => {
    if (auth.isAuthenticated && auth?.tokens && auth?.tokens?.user_id) {
      if (auth?.tokens?.user_id?.toString()) {
        getUserChannels({ user_id: auth?.tokens?.user_id });
      }
      getAllOrganizationChannels();
    }
  }, [
    auth.organizationId,
    auth.isAuthenticated,
    auth.onboardingStatus,
    auth?.tokens?.user_id,
  ]);

  useEffect(() => {
    if (auth.isAuthenticated && auth?.tokens && auth?.tokens?.user_id) {
      searchChannels(channelsState.filterParams);
    }
  }, [channelsState.filterParams]);

  const getChannel = async (id: string) => {
    try {
      const channel = await ChannelsAPI.getChannel(id);

      return channel;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const getChannelById = (id: string) => {
    const channel = channelsState.channels.find((channel: Channel) => channel.id === id);

    return channel as Channel;
  };

  const getUserChannels = async (params: {
    user_id?: number;
  }): Promise<Channel[] | undefined> => {
    // convert params to query string and remove empty params
    const stringParams = { user_id: params.user_id ? params.user_id.toString() : '' };
    // concat all params into a query string
    const queryParams = new URLSearchParams(stringParams).toString();

    // if we don't have a user_id, don't make the request
    // because all of the channels will be returned
    if (queryParams.length === 0) {
      return;
    }

    try {
      const data = await ChannelsAPI.fetchChannels(queryParams);

      dispatch({
        type: ChannelActionTypes.GET_CHANNELS,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const getAllOrganizationChannels = async () => {
    try {
      const data = await ChannelsAPI.fetchChannels();

      dispatch({
        type: ChannelActionTypes.GET_ALL_CHANNELS,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const addChannel = async (channel: Partial<Channel>) => {
    try {
      const data = await ChannelsAPI.createChannel(channel);

      dispatch({
        type: ChannelActionTypes.ADD_CHANNEL,
        payload: data,
      });
      searchChannels(channelsState.filterParams);
      toast.success('Channel added');
    } catch (err) {
      toast.error('Failed to add channel');
      console.error(err);

      return Promise.reject(err);
    }
  };

  const updateChannel = async (channel: Channel) => {
    try {
      const data = await ChannelsAPI.updateChannel(channel);

      dispatch({
        type: ChannelActionTypes.UPDATE_CHANNEL,
        payload: data,
      });

      toast.success('Channel updated');
    } catch (err) {
      toast.error('Channel update failed');

      console.error(err);

      return Promise.reject(err);
    }
  };

  const disableChannel = async (channel: Channel) => {
    try {
      const data = await ChannelsAPI.disableChannel(channel);

      dispatch({
        type: ChannelActionTypes.DISABLE_CHANNEL,
        payload: data,
      });
      searchChannels(channelsState.filterParams);
      toast.success('Disabled channel');
    } catch (err) {
      toast.error('Failed to disable channel');

      console.error(err);

      return Promise.reject(err);
    }
  };

  const enableChannel = async (channel: Channel) => {
    try {
      const data = await ChannelsAPI.updateChannel({
        ...channel,
        state: ChannelsStates.ENABLED,
      });

      dispatch({
        type: ChannelActionTypes.ENABLE_CHANNEL,
        payload: data,
      });
      searchChannels(channelsState.filterParams);
      toast.success('Enabled channel');
    } catch (err) {
      toast.error('Failed to enable channel');

      console.error(err);

      return Promise.reject(err);
    }
  };

  const archiveChannel = async (channel: Channel) => {
    try {
      const data = await ChannelsAPI.updateChannel({
        ...channel,
        state: ChannelsStates.ARCHIVED,
      });

      dispatch({
        type: ChannelActionTypes.ARCHIVE_CHANNEL,
        payload: data,
      });
      searchChannels(channelsState.filterParams);
      toast.success('Archived channel');
    } catch (err) {
      toast.error('Failed to archive channel');

      console.error(err);

      return Promise.reject(err);
    }
  };

  const setCurrent = (channel: Channel | null) => {
    dispatch({ type: ChannelActionTypes.SET_CURRENT, payload: channel });
  };

  const clearCurrent = () => {
    dispatch({ type: ChannelActionTypes.CLEAR_CURRENT });
  };

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const searchChannels = async (params: SearchFilters) => {
    try {
      const data = await ChannelsAPI.getChannelsV2(prepareFilters(params));

      dispatch({
        type: ChannelActionTypes.SEARCH_CHANNELS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateFilterParams = async (params: SearchFilters) => {
    try {
      dispatch({
        type: ChannelActionTypes.UPDATE_FILTER_PARAMS,
        payload: params,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ChannelContext.Provider
      value={{
        channelsState,
        getChannel,
        addChannel,
        setCurrent,
        clearCurrent,
        updateChannel,
        disableChannel,
        getUserChannels,
        getAllOrganizationChannels,
        isOpen,
        onOpen,
        onClose,
        getChannelById,
        searchChannels,
        updateFilterParams,
        enableChannel,
        archiveChannel,
      }}
    >
      {children}
    </ChannelContext.Provider>
  );
};

export default ChannelState;
