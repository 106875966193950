/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/react';
import dayjs from 'dayjs';
import i18next from 'i18next';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { useUserPreferences } from '@/pages/settings/user/preferences/context/PreferencesContext';
import { MessageEditorContainer } from '@/shared/components/editor/Styles';
import { useOutsideClick } from '@/shared/hooks';
import { useResizeObserver } from '@/shared/hooks/useResizeObserver';
import { Attachment, Contact, Signature } from '@/shared/types';
import { Channel } from '@/shared/types/channels';
import { ConversationScheduledMessageType } from '@/shared/types/conversations';
import { Template } from '@/shared/types/templates';

import { useConversation } from '../../../../pages/inbox/context/ConversationContext';
import { AttachmentsStack } from '../../attachments';
import { fillPipeFields } from '../Pipes';
import { Attachments } from './constants';
import { EmojiData, PipeData } from './constants';
import { useFocusedContext } from './context/FocusedContext';
import { Editor } from './Editor';
import { MessageToolbarV2 } from './MessageToolbar';
import { MessageTopbar } from './MessageTopbar';
import { isTimeInThePast, SuggestedItem } from './utils';

export type ScheduleParams = {
  day: string;
  month: string;
  year: string;
  hour: string;
  minute: string;
  timezone: string;
};

// the size of all the icons in the editor
export const ICON_SIZE = 16;
// the max number of characters allowed in the editor
export const MAX_CHARACTERS_COUNT = 1000;
// At what editor width should we switch to icons buttons
export const USE_ICON_BUTTONS_AT = 810;

export type MessageEditorV2Props = {
  // Message Editor state props
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  attachments?: Attachments;
  setAttachments?: Dispatch<SetStateAction<Attachments>>;
  scheduleParams?: ScheduleParams;
  setScheduleParams?: Dispatch<SetStateAction<ScheduleParams>>;
  signature?: Signature | null;
  setSignature?: Dispatch<SetStateAction<Signature | null>>;
  attachmentLoading?: boolean;
  setAttachmentLoading?: Dispatch<SetStateAction<boolean>>;

  // Socket status: Used in ConversationEditor where there is socket state
  socketConnected?: boolean;

  // UI Feature Toggles (all default to false, set to true to enable)
  showAddAttachment?: boolean;
  showAddTemplate?: boolean;
  showAddReview?: boolean;
  showAddVariable?: boolean;
  showAddEmoji?: boolean;
  showAddTranslate?: boolean;
  showAddSchedule?: boolean;
  showAddSignature?: boolean;
  showCharacterCount?: boolean;
  showSendButton?: boolean;
  showAutoComplete?: boolean;
  showSentenceGenerator?: boolean;
  showAddNote?: boolean;
  showShortcuts?: boolean;
  showKeyboardSendShortcut?: boolean;

  channel_type?: 'phone' | 'email' | 'whatsapp';

  // Contextual Information props
  source?: string; // source of the editor eg mainEditor, updateTemplate etc. Used for putting ID on upload files. This prevents rendering issues with React mounting as the Upload component has the same ID
  placeholder?: string;
  sendButtonCopy?: string;
  sendMessageFromTranslationDialog?: boolean;
  contact?: Partial<Contact>;
  location?: Channel;
  isDraftDisabled?: boolean;
  isForm?: boolean;
  enableAttachments?: boolean;
  enableEnterToSend?: boolean;
  scheduledMessages?: Array<ConversationScheduledMessageType>;
  typingUsers?: string | null;

  // Action Override related props
  sendMessageAction?: () => void; // overwrite default for sending message
  scheduleSendAction?: (params: ScheduleParams) => void; // overwrite default for sending scheduled message
  translateMessageAction?: (
    originalMessage: string,
    translatedMessage: string,
    attachmentUrls: string[]
  ) => void; // this is an overwrite function, if exists, it will be called instead of local implementation of translate
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  isReviewResponse?: boolean;
  isInbox?: boolean;
};

export function MessageEditorV2(props: MessageEditorV2Props): JSX.Element {
  const {
    message,
    setMessage,
    attachments,
    setAttachments,
    socketConnected,
    showAddAttachment,
    showAddTemplate,
    showAddReview,
    showAddVariable,
    showAddEmoji,
    showAddTranslate,
    showAddSchedule,
    showAddSignature,
    showCharacterCount,
    showSendButton,
    showShortcuts,
    scheduleParams,
    setScheduleParams,
    signature,
    setSignature,
    enableAttachments,
    enableEnterToSend,
    sendMessageAction,
    scheduleSendAction,
    translateMessageAction,
    showAutoComplete,
    showSentenceGenerator,
    showAddNote,
    attachmentLoading,
    setAttachmentLoading,
    source,
    placeholder,
    contact,
    textareaRef,
    sendMessageFromTranslationDialog,
    channel_type = 'phone',
    isReviewResponse,
    isInbox,
  } = props;

  // Refs
  const emojiButton = useRef<HTMLButtonElement>(null);
  const templateButton = useRef<HTMLDivElement>(null);
  const reviewButton = useRef<HTMLDivElement>(null);
  const aiBoxButton = useRef<HTMLButtonElement>(null);

  // State
  const [characterCount, setCharacterCount] = useState(0);
  const [editorWidth, setEditorWidth] = useState<number>();
  const [anchorDimensions, setAnchorDimensions] = useState({
    height: 0,
    width: 0,
  });

  // Context
  const isDesktop = useMedia('(min-width: 768px)');
  const { addScheduledMessage } = useConversation();

  const {
    mentionActive,
    isNote,
    shortcutActive,
    emojiModalActive,
    setEmojiModalActive,
    setTemplatesModalActive,
    templatesModalActive,
    aiModalActive,
    scheduledModalActive,
    translateModalActive,
  } = useFocusedContext();

  const editorWidthRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useResizeObserver(
    (data: ResizeObserverEntry[]) => setEditorWidth(data[0].contentRect.width),
    editorWidthRef
  );

  const { getUsers, userState } = useUsers();
  const { users } = userState;

  const authContext = useAuth();

  const { preferences } = useUserPreferences();

  useMemo(() => {
    // Load in users for user tagging for @mentions
    // Only load if users are not already loaded
    if (!users.length) {
      getUsers({});
    }
  }, []);

  // update anchor dimensions when window resizes
  useEffect(() => {
    window.addEventListener('resize', updateAnchorDimensions);
    updateAnchorDimensions();
    return () => {
      window.removeEventListener('resize', updateAnchorDimensions);
    };
  }, []);

  const updateAnchorDimensions = () => {
    let actionContainerHeight = 82;
    if (!isDesktop) {
      actionContainerHeight = 80;
    }

    if (showAddNote) {
      actionContainerHeight += 40;
    }

    const hasAttachments = attachments?.attachment_urls?.length ?? false;
    if (attachmentLoading || hasAttachments) {
      actionContainerHeight += 100;
    }

    if (!textareaRef || !textareaRef.current) return;
    const height = textareaRef.current.offsetHeight + actionContainerHeight || 0;
    const width = textareaRef.current.offsetWidth || 0;

    setAnchorDimensions({
      height,
      width,
    });
  };

  const insertIntoEditor = (text: string) => {
    try {
      const editor = textareaRef.current;
      if (!editor) throw new Error('editor not found');

      const { selectionStart, selectionEnd } = editor;
      const editorValue = editor.value || '';
      const newValue = `${editorValue.substring(
        0,
        selectionStart
      )}${text}${editorValue.substring(selectionEnd)}`;

      setMessage(newValue);
      setCharacterCount(newValue.length);

      // When content is inserted in the editor,
      // the cursor is moved to the end of the inserted content
      // and the editor is focused so the user can continue typing
      const endCursorPosition = selectionEnd + text.length;
      editor.focus();
      editor.setSelectionRange(endCursorPosition, endCursorPosition);

      updateAnchorDimensions();
    } catch (error) {
      console.log(error);
      console.error(error);
      captureException(error, {
        tags: {
          feature: 'MessageEditorV2',
          action: 'insertIntoEditor',
        },
      });
    }
  };

  const onAIOptionClick = async (selectedOption: SuggestedItem) => {
    try {
      insertIntoEditor(selectedOption.message);
    } catch (error) {
      captureException(error, {
        tags: {
          feature: 'message_editor',
          action: 'onAIOptionClick',
        },
      });
    }
  };

  const setTemplateMessage = (template: Template, templateMessage: string) => {
    insertIntoEditor(templateMessage);
    setTemplatesModalActive(false); // this is used to change the default Enter key behavior

    // Focus cursor at the end of the textarea
    if (textareaRef.current) {
      textareaRef.current.setSelectionRange(
        textareaRef.current.value.length,
        textareaRef.current.value.length
      );
    }

    if (template.attachments) {
      if (setAttachmentLoading) setAttachmentLoading(true);
      if (!setAttachments) return;

      // Merge attachments so there are no duplicates
      const newAttachments = new Set([
        ...template.attachments.map((att: Attachment) => att.url),
        ...(attachments != undefined ? attachments?.attachment_urls || [] : []),
      ]);

      setAttachments({ attachment_urls: [...newAttachments] });
      if (setAttachmentLoading) setAttachmentLoading(false);
    }
  };

  const onTemplateClick = async (template: Template | undefined | null) => {
    if (template) {
      let templateMessage: string = template.message || '';

      // Apply pipe fields
      const contactName: string = contact?.name || '{{first_name}}';
      const location = props?.location;
      const locationName = location?.name || '{{location_name}}';
      const locationAddress = location?.address || '{{location_address}}';
      const locationId = location?.id || '{{location_id}}';
      const googlePlaceId = location?.google_place_id;
      const organizationName = authContext?.organizationName || '{{organization_name}}';

      templateMessage = fillPipeFields(
        templateMessage,
        contactName,
        locationName,
        locationAddress,
        googlePlaceId,
        organizationName,
        null, // TODO: add in conversationId
        locationId
      );

      if (
        template.is_scheduled_template &&
        template?.scheduled_template_params &&
        !isReviewResponse &&
        isInbox
      ) {
        const date = dayjs()
          .add(template?.scheduled_template_params?.days_in_the_future || 0, 'day')
          .format('MM/DD/YYYY');
        const day = date.split('/')[1];
        const month = date.split('/')[0];
        const year = date.split('/')[2];
        const scheduleMessageParams = {
          day,
          month,
          year,
          hour: `${template?.scheduled_template_params?.hour || '00'}`,
          minute: `${template?.scheduled_template_params?.minute || '00'}`,
          timezone: `${template?.scheduled_template_params?.timezone || ''}`,
        };

        // is the date in the past
        const todaysDate = dayjs(
          new Date().toLocaleString('en-US', {
            timeZone:
              template?.scheduled_template_params?.timezone ||
              Intl.DateTimeFormat().resolvedOptions().timeZone,
          })
        ).format('MM/DD/YYYY');

        // is the time in the past
        const todaysTime = dayjs(
          new Date().toLocaleString('en-US', {
            timeZone:
              template?.scheduled_template_params?.timezone ||
              Intl.DateTimeFormat().resolvedOptions().timeZone,
          })
        ).format('H:mm');

        if (
          !(
            isTimeInThePast(
              `${scheduleMessageParams?.hour}:${scheduleMessageParams?.minute}`,
              todaysTime
            ) &&
            `${scheduleMessageParams?.month}/${scheduleMessageParams?.day}/${scheduleMessageParams?.year}` ===
              todaysDate
          )
        ) {
          scheduleSendAction
            ? scheduleSendAction?.(scheduleMessageParams)
            : addScheduledMessage(
                {
                  body: templateMessage,
                  attachment_urls: attachments?.attachment_urls || [],
                },
                scheduleMessageParams
              );
          setTemplatesModalActive(false);
        } else {
          setTemplateMessage(template, templateMessage);
        }
      } else {
        setTemplateMessage(template, templateMessage);
      }
    }
  };

  // Update templates modal state on outside click
  // This state is used to change the default Enter key behavior
  useOutsideClick({
    ref: templateButton,
    handler() {
      setTemplatesModalActive(false);
      textareaRef.current?.focus();
    },
    enabled: templatesModalActive,
  });

  const insertPipe = (pipe: string) => {
    insertIntoEditor(pipe);
  };

  const onPipeClick = async (selectedPipe: PipeData) => {
    const pipe = selectedPipe?.children || '';
    insertPipe(pipe);
    if (textareaRef.current) textareaRef.current.focus();
  };

  const onEmojiClick = (selectedEmoji: EmojiData) => {
    insertIntoEditor(selectedEmoji.emoji);
    emojiButton.current?.click(); // clicking the button again closes the emoji modal
    setEmojiModalActive(false); // this is used to change the default Enter key behavior
    if (textareaRef.current) textareaRef.current.focus(); // focus back on the textarea
  };

  // Update emoji modal state on outside click
  // This state is used to change the default Enter key behavior
  useOutsideClick({
    ref: emojiButton,
    handler() {
      textareaRef.current?.focus();
      setEmojiModalActive(false);
    },
    enabled: emojiModalActive,
  });

  // Note: some of the keyboard shortcuts are feature flagged with flag conversationKeyboardShortcuts
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // When a user exits a dialog, the editor should refocus the textarea
    if (e.key === 'Escape') {
      e.preventDefault();
      setEmojiModalActive(false);
      setTemplatesModalActive(false);
      if (textareaRef.current)
        textareaRef.current.setSelectionRange(
          textareaRef.current.value.length,
          textareaRef.current.value.length
        );
    }

    // Keyboard send message shortcut
    const isEnterToSend = preferences?.inbox?.enter_to_send
      ? e.key === 'Enter' && !e.shiftKey
      : e.shiftKey && e.key === 'Enter';

    if (isEnterToSend && sendMessageAction) {
      // If the @mention search is active, ignore the send key as the Enter key is used to select a contact; or if its a form
      if (
        mentionActive ||
        shortcutActive ||
        emojiModalActive ||
        templatesModalActive ||
        aiModalActive ||
        props.isForm ||
        !enableEnterToSend ||
        scheduledModalActive ||
        translateModalActive
      )
        return;
      e.preventDefault();

      if (socketConnected === false) {
        // socketConnected is used in ConversationEditor where there is socket state
        // If the socket is not connected, show a toast message
        // If socketConnected is not defined, it skips this check
        toast('Connecting to Whippy...', { icon: '⏳' });
      } else if (attachmentLoading) {
        toast('Processing uploaded file...', { icon: '⏳' });
      } else {
        // If custom send action is present, execute it
        if (sendMessageAction) sendMessageAction();
        else alert('send message action not present');
      }
    }
  };

  const handleScheduledSend = () => {
    if (
      (!message || message === '') &&
      (!attachments || attachments?.attachment_urls.length === 0)
    )
      return;

    if (!scheduleParams) return;

    addScheduledMessage(
      {
        body: message,
        attachment_urls: attachments?.attachment_urls || [],
      },
      scheduleParams
    );

    // Reset message and attachments state
    setMessage('');
    if (!setAttachments) return;
    setAttachments({ attachment_urls: [] });
  };

  const handleDeleteAttachment = async (public_url: string) => {
    if (!setAttachments) return;

    // Get the current attachments state
    const prevAttachments =
      attachments !== undefined ? attachments : { attachment_urls: [] };

    // Filter out the attachment with the given public_url
    const newAttachmentUrls = prevAttachments.attachment_urls.filter(
      (url) => url !== public_url
    );

    // Create a new Attachments object with the updated URLs
    const newAttachments: Attachments = {
      ...prevAttachments,
      attachment_urls: newAttachmentUrls,
    };

    // Update the attachments state with the new Attachments object
    setAttachments(newAttachments);
    toast.success(i18next.t('delete_success') as string);
  };

  // insert signature
  const onSignatureClick = async (newSignature: Signature) => {
    try {
      if (!showAddSignature || setSignature === undefined || signature === undefined) {
        const errorMessage = i18next.t('add_signature_error');
        return toast.error(errorMessage, { icon: '🔧' });
      }

      if (newSignature === signature) {
        setSignature(null);
        toast.success(i18next.t('signatures_updated_remove') as string);
      } else {
        setSignature(newSignature);
        toast.success(i18next.t('signature_updated_success') as string);
      }
    } catch (error) {
      captureException(error, {
        tags: {
          function: 'onSignatureClick',
          feature: 'message_editor',
        },
      });
    }
  };

  const onReviewInviteClick = async () => {
    focus();
    const contactName = props.contact?.name || '';
    const organizationName = authContext?.organizationName || '';
    const googlePlaceId = props.location?.google_place_id || '';
    if (!googlePlaceId)
      return toast(`Google Place ID not found. Update this in location settings.`);
    const reviewLink = `http://search.google.com/local/writereview?placeid=${googlePlaceId}`;

    insertIntoEditor(
      `Hey, ${contactName} thanks for choosing ${organizationName}. Could you please take 30 seconds to leave us a review at this link? - ${reviewLink}`
    );
    focus();
  };

  return (
    <>
      <MessageEditorContainer
        direction="column"
        css={{
          width: '100%',
          height: 'inherit',
          position: 'relative',
          wordBreak: 'break-word',
          borderBottom: '0px',
          boxShadow: 'inset 0 0 0 1px #0000301B',
          borderRadius: '$2',
          backgroundColor: isNote && showAddNote ? '#FFECB7' : 'white',
          variants: {
            focus: {
              true: {
                boxShadow:
                  'rgba(62, 84, 207, 0.7) 0px 0px 0px 1.5px inset, rgba(62, 84, 207, 0.1) 0px 0px 0px 4px;',
              },
            },
          },
        }}
        ref={editorWidthRef}
        onKeyDown={handleKeyDown}
      >
        {enableAttachments && (
          <AttachmentsStack
            attachments={props?.attachments?.attachment_urls || []}
            deleteHandler={handleDeleteAttachment}
            loading={attachmentLoading}
          />
        )}
        {showAddNote && <MessageTopbar textareaRef={textareaRef} />}
        <Editor
          message={message}
          setMessage={setMessage}
          setCharacterCount={setCharacterCount}
          source={source}
          users={users}
          showAutoComplete={showAutoComplete}
          emojiButton={emojiButton}
          templateButton={templateButton}
          reviewButton={reviewButton}
          aiBoxButton={aiBoxButton}
          showAddNote={showAddNote}
          showAddTemplate={showAddTemplate}
          showAddReview={showAddReview}
          showAddEmoji={showAddEmoji}
          showShortcuts={showShortcuts}
          textareaRef={textareaRef}
          placeholder={placeholder}
          channel_type={channel_type}
        />
        <MessageToolbarV2
          attachments={attachments}
          source={source}
          attachmentLoading={attachmentLoading}
          setAttachmentLoading={setAttachmentLoading}
          textareaRef={textareaRef}
          emojiButton={emojiButton}
          templateButton={templateButton}
          reviewButton={reviewButton}
          aiBoxButton={aiBoxButton}
          // If sendButtonCopy is provided, use that, otherwise implement note logic
          sendButtonCopy={props.sendButtonCopy || (isNote ? 'Add Note' : 'Send')}
          sendMessageFromTranslationDialog={sendMessageFromTranslationDialog}
          anchorDimensions={anchorDimensions}
          onSignatureClick={onSignatureClick}
          characterCount={characterCount}
          editorWidth={editorWidth}
          handleScheduledSend={handleScheduledSend}
          message={message}
          onEmojiClick={onEmojiClick}
          onPipeClick={onPipeClick}
          onReviewInviteClick={onReviewInviteClick}
          onTemplateClick={onTemplateClick}
          scheduleParams={scheduleParams}
          setScheduleParams={setScheduleParams}
          showAddSignature={showAddSignature}
          setMessage={setMessage}
          showSentenceGenerator={showSentenceGenerator}
          showAddAttachment={showAddAttachment}
          showAddEmoji={showAddEmoji}
          showAddReview={showAddReview}
          showAddSchedule={showAddSchedule}
          showAddTemplate={showAddTemplate}
          showAddTranslate={showAddTranslate}
          showAddVariable={showAddVariable}
          showCharacterCount={showCharacterCount}
          showSendButton={showSendButton}
          signature={signature}
          setSignature={setSignature}
          updateAnchorDimensions={updateAnchorDimensions}
          setAttachments={setAttachments}
          sendMessageAction={sendMessageAction}
          location={props.location}
          scheduleSendAction={scheduleSendAction}
          translateMessageAction={translateMessageAction}
          onAIOptionClick={onAIOptionClick}
          channel_type={channel_type}
          isReviewResponse={isReviewResponse}
          isInbox={isInbox}
        />
      </MessageEditorContainer>
    </>
  );
}
