import { toast } from 'sonner';

import { OpeningHours } from '@/shared/components/openingHours';
import { OpeningHour } from '@/shared/types';
import { Channel } from '@/shared/types/channels';
import i18next from '@/shared/utils/translation';

import { useChannels } from '../context/ChannelContext';

const order: Record<string, number> = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

// Sorts the days of the week from Monday to Sunday
const sortDays = (openingHours: Array<OpeningHour>) => {
  return openingHours?.sort((a: OpeningHour, b: OpeningHour) => {
    return order[a.weekday] - order[b.weekday];
  });
};

const applyNewOpeningHour = (location: Channel, newOpeningHour: OpeningHour): Channel => {
  const updatedOpeningHours = [
    ...(location?.opening_hours?.filter(
      (existingOpeningHour: OpeningHour) => existingOpeningHour.id !== newOpeningHour.id
    ) || []),
    newOpeningHour,
  ];
  return {
    ...location,
    opening_hours: updatedOpeningHours,
  };
};

export const BusinessHours = () => {
  const { channelsState, updateChannel } = useChannels();
  const { current } = channelsState;

  // Updates the opening hours
  const onChange = (openingHour: OpeningHour) => {
    // Throws an error when there are no opens_at or closes_at and the state is open
    if (
      (openingHour.opens_at === null || openingHour.closes_at === null) &&
      openingHour.state === 'open'
    ) {
      toast.error(i18next.t('working_hours_not_set') as string);
      return;
    }

    // Update the location with the new opening hours
    if (current && current.id) {
      delete openingHour.isSwitch;
      delete openingHour.timezone;
      const modifiedLocation = applyNewOpeningHour(current, openingHour);
      updateChannel(modifiedLocation);
    }
  };

  // Enforces the order of the days of the week, because the API returns them in a random order
  const sortedOpeningHours = sortDays(current?.opening_hours || []);

  // Applies the opening hours to the selected days
  const onApply = async (openingHour: OpeningHour, targetDays: string[]) => {
    // If there is no team, return
    if (!current || !current.id) {
      return;
    }

    const updatedOpeningHours = current?.opening_hours?.map((day) => {
      if (targetDays.includes(day.weekday)) {
        return {
          ...day,
          opens_at: openingHour.opens_at,
          closes_at: openingHour.closes_at,
          state: openingHour.state,
        };
      }
      return day;
    });

    updateChannel({ ...current, opening_hours: updatedOpeningHours });
  };

  // Update the existing opening hours with timezone
  const updateTimezone = async (timezoneName: string) => {
    updateChannel({
      ...(current as Channel),
      timezone: timezoneName,
      id: current?.id || '',
    });
  };

  return (
    <OpeningHours
      onChange={onChange}
      onApply={onApply}
      updateTimezone={updateTimezone}
      timezone={current?.timezone}
      current={{ ...current, openingHours: sortedOpeningHours }}
    />
  );
};
