import React from 'react';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { Channel } from '@/shared/types/channels';
import { Box } from '@/shared/ui';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';

type SelectLocationProps = {
  /** the location the campaign is scheduled */
  location: string;
  /** set the location the campaign is scheduled */
  setLocation: (location: string) => void;
  /** is the select disabled? */
  disabled?: boolean;
};

export const SelectLocation = (props: SelectLocationProps): JSX.Element => {
  const { location, setLocation, disabled } = props;

  const channels = useChannels();
  const { channelsState } = channels;

  const getLocationName = (id: string) =>
    channelsState.channels.find((location) => location.id === id)?.name;

  const generateLocationName = (location: Channel) => {
    switch (location.type) {
      case 'phone':
        return `SMS: ${location.name} - ${formatPhoneNumber(location.phone)}`;
      case 'email':
        return `Email: ${location.name}`;
      case 'whatsapp':
        return `WhatsApp: ${location.name}`;
      default:
        return `SMS: ${location.name} - ${formatPhoneNumber(location.phone)}`;
    }
  };

  // map over channels and filter out channels that are not enabled
  const enabledChannels = channelsState.channels.filter(
    (channel: Channel) => channel.state === 'enabled'
  );

  return (
    <Box>
      {channelsState.channels.length > 0 && (
        <SingleSelect
          disabled={disabled ? true : false}
          selectItem={location}
          setSelectItem={setLocation}
          closeOnClick={true}
          options={enabledChannels.map((location: Channel) => ({
            type: generateLocationName(location),
            value: location?.id,
          }))}
          defaultPlaceholder={getLocationName(location || '') || 'Select Channel'}
          isDropdown={true}
        />
      )}
    </Box>
  );
};
